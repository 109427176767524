import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore' ;
import {useCookies} from 'react-cookie'

import Accordion from '@mui/material/Accordion';
import{ AccordionSummary,AccordionDetails} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowBack';
import './D6_Sl_Drawer.css'
export default function TemporaryDrawer(props) {
  const[auth,setAuth,deleteAuth]=useCookies(['auth-kf'])

  const [open,setOpen]=React.useState(true)


  

  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
const signoutClicked=()=>{
  deleteAuth(['auth-kf'])
  window.location.href='/'
}
  const list = (anchor) => (
    <Box
      className='box_W'
      role="presentation"
      onClick={Open}
      onKeyDown={Open} 
      
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon style={{cursor:'pointer',paddingBottom:'2px',color:'var(--redColor)'}}>
      <ArrowForwardIcon  />
      </ListItemIcon>
      </ListItem>


    


        {(['HOME','ABOUT US']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='HOME'?'/':text=='ABOUT US'?'/aboutus':text=='Contact us'?'/contactus':text=='Company Compliance'?'/companycompliance':text=='Our services'?'ourservices':'/'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
    <Accordion>
        <AccordionSummary
                style={{margin:'5px 0px'}}

          expandIcon={<ExpandMoreIcon style={{color:'var(--redColor)'}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
        >
          <Typography className='typo'  >COMPLIANCE MANAGEMENT</Typography>
        </AccordionSummary>

        <AccordionDetails style={{display:'grid'}}>

        <Accordion>
        <AccordionSummary 
        // style={{margin:'15px 0px'}}
          expandIcon={<ExpandMoreIcon style={{color:'var(--redColor)'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>COMPLIANCE MANAGEMENT - COMBO PRODUCTS</Typography>
                    </AccordionSummary>

          <AccordionDetails>
        <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-Loan Company + Policies Draft</Typography>

    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-MFI + Policies Draft</Typography>
   
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-Loan Company + Policies Draft</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-MFI + Policies Draft</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-MFI</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-Loan Company</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-MFI</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-Loan Company</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + Legal Drafts</Typography>
    <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + Legal Drafts</Typography>
    {/* <Typography className='typo4' onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + MFI + Policies Draft</Typography> */}

          </AccordionDetails>
        
          </Accordion>
          <Accordion>
        <AccordionSummary 
        // style={{margin:'15px 0px'}}
          expandIcon={<ExpandMoreIcon style={{color:'var(--redColor)'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>COMPLIANCE MANAGEMENT - INDIVIDUAL PRODUCTS</Typography>
                    </AccordionSummary>

          <AccordionDetails>

          <Accordion>
        <AccordionSummary 
        // style={{margin:'15px 0px'}}
          expandIcon={<ExpandMoreIcon style={{color:'var(--redColor)'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>Non-Banking Finance Company-Non Deposit taking</Typography>
                    </AccordionSummary>

          <AccordionDetails>


            
          <Typography className='typo4' onClick={()=>window.location.href="/compliance-management/nbfc"}>Loan Company </Typography>
   
   <Typography className='typo4' onClick={()=>window.location.href="/compliance-management/nbfc"}>Micro Finance Institution (NBFC-MFI) </Typography>
   
          </AccordionDetails>
        
          </Accordion>
          <Typography className='typo4' onClick={()=>window.location.href="/compliance-management/public-limited-company"}>Public Limited Company </Typography>

<Typography className='typo4' onClick={()=>window.location.href="/compliance-management/private-limited-company"}>Private Limited Company </Typography>
<Typography className='typo4' onClick={()=>window.location.href="/compliance-management/legal-drafts"}>Legal Drafts</Typography>

<Typography className='typo4' onClick={()=>window.location.href="/compliance-management/one-person-company"}>One Person Company (OPC)</Typography>
{/* <Typography className='typo4' onClick={()=>window.location.href="/ourcompliance-management/taxation-compliance-management"}>Limited Liability Partnership (LLP)</Typography> */}
<Typography className='typo4' onClick={()=>window.location.href="/compliance-management/draft-policies"}>Policies Draft</Typography>

          </AccordionDetails>
        
          </Accordion>
         
         
       
        </AccordionDetails>
      </Accordion>
      {(['ARTICLES','CONTACT US']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='CONTACT US'?'/contactus':text=='SIGN IN / SIGN UP'?'/signin':text}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
        {!auth['auth-kf']?
        
        (['SIGN IN / SIGN UP' ]).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='CONTACT US'?'/contactus':text=='SIGN IN / SIGN UP'?'/signin':text}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        )):<Accordion>
        <AccordionSummary 
        style={{margin:'15px 10px'}}
          expandIcon={<ExpandMoreIcon style={{color:'var(--redColor)'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>MY ACCOUNT</Typography>
                    </AccordionSummary>

          <AccordionDetails>
        <Typography className='typo4' onClick={()=>window.location.href="/dashboard"}>Dashboard</Typography>

    <Typography className='typo4' onClick={()=>signoutClicked()}>Signout</Typography>
   
  
          </AccordionDetails>
        
          </Accordion>}
     
    
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={'left'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}