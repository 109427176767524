import {Chip, Container, Grid, Paper, Typography } from "@mui/material";
import React,{useRef,useEffect} from "react";
import { serviceData } from "../../services/mainServices/serviceData";
import './m&v.css'
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SignIND from "../../authentication/signin/signInD";
import {useCookies} from 'react-cookie';
import { useState } from "react";
import Payment from "../../payment/payment";
import Image1 from '../../../images/clients1.svg'
import Image2 from '../../../images/registration1.svg'

export default function MissionV(props){
const {pricing}=props
const myRef = useRef(null)

const pricing1=pricing.filter((val)=>val.category.includes('Compliance Management-Individual Products'))
    
const pricing2=pricing.filter((val)=>val.category.includes('Compliance Management-Combo Products'))


const [open, setOpen] = React.useState(false);
    const[auth,setAuth]=useCookies(['auth-kf'])
const [payment,setPayment]=useState(false)
const [price,setPrice]=useState('')
const [plan,setPlan]=useState('')


useEffect(()=>{
    if(myRef&&myRef.current)
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
    
  },[payment])
    const handleClose = () => {
        // console.log('success')
        setOpen(false);
    };


    const payClicked=(val,val1)=>{
        if(auth["auth-kf"]){
            setPrice(val)
            setPlan(val1)
            setPayment(true)
        }
        else{

            setOpen(true)
        }
    }
    
    const backClicked=()=>{

        setPayment(false)

    }
    return(
<>
<div  className="mv_bg">
<Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    <Container style={{height:'100%'}}>
        <Grid container style={{height:'100%'}}>
            <Grid sm={6}  xs={12}>
                <Grid sm={12} xs={12}>
<Typography className="mv_head">
<span style={{color:'var(--redColor)'}}>Our</span> Vision
</Typography>
            </Grid>
            <Grid sm={12}  xs={12}>
                <Typography className="mv_text">
                Our vision is to transform the current Compliance Management System into a more viable and user friendly interface and thereby up skills professionals so that they can contribute to the businesses with their high potential and correspondingly serve to the economy as a whole.
</Typography>
            </Grid>
            </Grid>
            <Grid sm={6}  xs={12}>
                <Grid sm={12} xs={12}>
<Typography className="mv_head">
<span style={{color:'var(--redColor)'}}>Our</span> Mission</Typography>
            </Grid>
            <Grid sm={12}  xs={12}>
                <Typography className="mv_text">
                <b> KnowledgeFocal.com </b>aims towards Value creation, with an object to seed and nurture the plants of knowledge, and transforms them into a beautiful garden of excellence.
</Typography>
            </Grid>
            </Grid>
     
        </Grid>
        
    </Container>

   

            
 

</div>


<Container>
        <Grid  container className="mainContS" style={{marginBottom:30}}>
        <Grid container  style={{marginTop:20}} sm={6} xs={12}>
            <Grid item style={{alignSelf:'center',textAlign:'center'}} sm={12} xs={12}>
                <img src={Image1} className='home_icon' />
            </Grid>
            <Grid item style={{alignSelf:'center',textAlign:'center'}} sm={12} xs={12}>
<Typography className="homeIcon_text">
    225+ Registered users
</Typography>
            </Grid>
            </Grid>
            <Grid container style={{marginTop:20}} sm={6} xs={12}>
            <Grid item style={{alignSelf:'center',textAlign:'center'}} sm={12} xs={12}>
                <img src={Image2} className='home_icon' />
            </Grid>
            <Grid item style={{alignSelf:'center',textAlign:'center'}} sm={12} xs={12}>
<Typography className="homeIcon_text">
    200+ Downloads
</Typography>
            </Grid>
            </Grid>
        
           
        </Grid>  

    </Container>

    <Container>
        <Grid  container className="mainContS" style={{marginBottom:30}}>
        <Grid sm={12} xs={12}>
<Typography className="mv_head" style={{width:'100%',textAlign:'center'}}>
<span style={{color:'var(--redColor)'}}>Our</span> Offerings</Typography>
            </Grid>
            <Grid item  sm={12} xs={12}>
                <Typography  className="mv_mainHead">
                We are offering dedicated and comprehensive Compliance Management Tools under the following categories.                  </Typography>
            </Grid>
           
        </Grid>  

    </Container>



    <Container>
        <Grid container className="mainContS" style={{marginBottom:30}}>
            <Grid item sm={12} xs={12}>
                <Typography className="services_mainHead">
                {/* Our Optimum Combination Services */}
                COMPLIANCE MANAGEMENT - INDIVIDUAL PRODUCTS

                </Typography>
            </Grid>
           {pricing1.sort((a,b)=>a.id>b.id?1:-1).map((val,idx)=>
                   <Grid  key={idx} className="sub_cont1S" container sm={4} xs={12}>
                   <Grid className="sub_contS" container sm={12} xs={12}>
                       <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
                       <i class="fas fa-file-invoice icon_services"></i>  
                       </Grid>
                       <Grid item sm={12} xs={12}>
                          <Typography  className="textS_head">
                            {val.planName}
                            </Typography>
                       </Grid>

                       <Grid item sm={12} xs={12}>
                   <Typography className="textS_text">
{val.description}                   <span  onClick={()=>window.location.href=`${val.url}`} style={{color:'var(--redColor)',paddingLeft:5}}>Read more</span>
                   </Typography>
                </Grid>
                <Grid item  style={{justifyContent:'center',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex'}}>
                        <Typography className="strike_amount" >
                            ₹{val.price}
                        </Typography>
                        <Typography className="discount" >
                       {val.discount}% OFF              
                     </Typography>
                    </div>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
                </Grid>
                       </Grid>
                   </Grid>) 
   }
          
         
            </Grid>

    </Container>
    <Container>
        <Grid container className="mainContS" style={{marginBottom:30}}>
            <Grid item sm={12} xs={12}>
                <Typography className="services_mainHead">
                {/* Our Optimum Combination Services */}
                COMPLIANCE MANAGEMENT - COMBO PRODUCTS

                </Typography>
            </Grid>
           {pricing2.sort((a,b)=>a.id>b.id?1:-1).map((val,idx)=>
                   <Grid  key={idx} className="sub_cont1S" container sm={6} xs={12}>
                   <Grid className="sub_contS" container sm={12} xs={12}>
                       <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
                       <i class="fas fa-file-invoice icon_services"></i>  
                       </Grid>
                       <Grid item sm={12} xs={12}>
                          <Typography  className="textS_head">
                            {val.planName}
                            </Typography>
                       </Grid>

                       <Grid item sm={12} xs={12}>
                   <Typography className="textS_text">
{val.description}                  
 {/* <span  onClick={()=>window.location.href=`${val.url}`} style={{color:'var(--redColor)',paddingLeft:5}}>Read more</span> */}
                   </Typography>
                </Grid>
                <Grid item  style={{justifyContent:'center',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex'}}>
                        <Typography className="strike_amount" >
                            ₹{val.price}
                        </Typography>
                        <Typography className="discount" >
                       {val.discount}% OFF              
                     </Typography>
                    </div>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
                </Grid>
                       </Grid>
                   </Grid>) 
   }
          
         
            </Grid>

    </Container>
    {payment&&price&&plan?<div ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></div>:  null}
</>
    )
}