import React, { useState } from 'react';
import { Data } from './D6_FE_Accordian_Data';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { Typography ,Grid, Chip, CircularProgress} from "@mui/material";
import './D6_FE_Accordian_CSS.css'
import { Container as Container1 } from '@mui/material';

const AccordionSection = styled.div`
margin-top:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/*  position: relative;  */
  height: 170vh;
  height: 100%;
 /* background: #fff;  */
  background: white;  
  z-index: 4;
  /*
  @media (max-width: 1200px) {
        height: 180vh ;
  }
  @media (max-width: 750px) {
        height: 110vh ;
  }
  @media (max-width: 660px) {
        height: 120vh ;
  }
  @media (max-width: 469px) {
        height: 135vh ;
  }
  @media (max-width: 430px) {
        height: 146vh ;
  }
  @media (max-width: 400px) {
        height: 154vh ;
  }
  @media (max-width: 350px) {
        height: 167vh ;
  }  */
`;

const Container = styled.div`
/*  position: absolute; */
  width: 100%;
  top: 30%;
/*  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);*/
`;

const Wrap = styled.div`
  background: #272727;
  background: white;
  
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  h1 {
    padding: 1rem;
    font-size: 1.3rem;
    text-align: left;
  }
  span {
    margin-right: 1.5rem;
  }
  @media (max-width: 750px) {
    h1 {
    font-size: 1.05rem;
    padding: 1rem;
    }
  }
  
`;

const Dropdown = styled.div`
  background: #1c1c1c;
  background: white;
  color: white;
  color: black;
  width: 100%;
  height: 130px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--blackColor);
  border-top: 1px solid white;
  border-top: 1px solid var(--blackColor);
  p {
    padding: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
    color: var(--blackColor);
    text-align: justify;
  }
  @media (max-width: 750px) {
    p {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    }
  }
`;

const Accordion_Article = (props) => {
const {articles}=props
  const [clicked, setClicked] = useState(0);

  const toggle = index => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };
const downloadClciked=(e,val)=>{
  e.preventDefault()
  let alink = document.createElement('a');
  alink.target="_self"

  alink.href = 'https://d6-kfocal.s3.ap-south-1.amazonaws.com/'+val.url; 
  alink.rel="noopener"


  alink.download = `${val.url.replace('Data/Articles/','')}`;
  alink.click();
}
  return (
<>
{/* <Container1>
  <Grid container>

  
  </Grid>
</Container1> */}

  {articles[0]?  <Container1 style={{marginBottom:10}}>
    
      <IconContext.Provider className="D6_FE_A_IC" value={{ color: 'var(--bcolor)', size: '25px' }}>
    
      <AccordionSection className="D6_FE_AS">
        <Container className="D6_FE_Cont">
          {articles.map((item, index) => {
            return (
              <>
                <Wrap className="D6_FE_Wrap" onClick={() => toggle(index)} key={index}>
                  <h1 style={{color:'var(--redColor)',fontWeight:'normal',textTransform:'uppercase'}}>{item.name}</h1>
                  <span>{clicked === index ? <FiMinus  style={{color:'var(--redColor)'}} /> : <FiPlus style={{color:'var(--redColor)'}} />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown className="D6_FE_DD">
                    <p>{item.description}</p>
                    <Chip   onClick={(e)=>downloadClciked(e,item)}  label='Download' className='download_chip'/>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
   

    </Container1>:
    <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
    <CircularProgress style={{justifyContent:'center',fontSize:40,marginTop:'5vh',marginBottom:'10vh'}} />
    </Grid> }
    </> );
};

export default Accordion_Article;