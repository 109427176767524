import React from 'react'
import './D6_SL_AF_CSS.css';
import {Grid,Container,Typography,Chip, Dialog, DialogContent, IconButton} from '@mui/material';
import {NavLink} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';



import './D6_SL_AF_CSS.css'
import D6_DASAC_Form from './D6_SL_AF_Form';


const D6_SL_AF = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
<>

<Container style={{maxWidth:'100%',textAlign:'-webkit-center'}}>


<Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your query here..            
          </Typography>
          <D6_DASAC_Form  />
        </DialogContent>
      </Dialog>

    
                  </Container>
   <div className="D6_AboveFooter_Container">   

 <Container  style={{maxWidth:1200}} fixed className="D6_AF_Grid_Cont">
 <Grid container justify="center" className="D6_AF_Grid">

        <Grid item md={6} xs={12} className="D6_AF_Grid3">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-3">
                <h3 className="D6_AboveFooter_H3">Popular Services</h3>
                <ul className="D6_AboveFooter_List3">
                <li>
                        <NavLink className=""  to="/compliance-management">
                        Individual Products                      </NavLink>
                    </li>
                    <li>
                        <NavLink className=""  to="/compliance-management">
                        Combo Products                       </NavLink>
                    </li>
                   
                    {/* <li>
                        <NavLink className=""  to="/ContactUs">
                        TradeMark Registration                        </NavLink>
                    </li>
                    
                    <li>
                        <NavLink className=""  to="/ContactUs">
                        Professional Tax Registration                        </NavLink>
                    </li> */}
                </ul>
             
            </div>
        </Grid>

        {/* <Grid item md={4} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Head Office (India)</h3>
                <div className="D6_AboveFooter_Item5D1">
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                    Office No-307/H-3, Third Floor,  

           Plaza Tower   </p>
                 
                    <p className="D6_AboveFooter_HC1">
                    Netaji Subhash Place,  

Pitampura, New Delhi-110034
                    </p>
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91-9717929729"className="D6_AboveFooter_Icon_H">
                        +91-9717929729 
                        </a>  
                    </div>
                 
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:infotbipl@gmail.com"  className="D6_AboveFooter_Icon_E">
                        info@knowledgefocal.com
                        </a>  
                    </div>
                   

                
                </div>
            </div>
        </Grid> */}

     
        <Grid item md={6} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Reach Us</h3>
                <div className="D6_AboveFooter_Item5D1">
                 
                 
                {/* <div className="Dec6_AboveFooter_SymbolsList1">
                <QRCode size={100} value={"Team Blue India"} fgColor={'#ffff'} bgColor={'#141e66'} level={'Q'}/>
    
                     
                    </div>  */}


                    <div className="Dec6_AboveFooter_SymbolsList1">
                        {/* <a href="'https://affiliate.fmstracking.online/"   target='_blank'   className="fas fa-user-lock circle-icon aicon centericons"></a> */}
                        <a href="https://www.linkedin.com/company/fillymedia.com"  className="fab fa-linkedin-in circle-icon1 circle-icon-tw aicon"></a>
                        <a href="https://www.facebook.com/FillyMediacom"  className="fab fa-facebook circle-icon1   ">
                      
                      
                        </a>
                        <a href="https://twitter.com/Fillymediacom"  className="fab fa-twitter circle-icon1   ">
</a>
    
                     
                    </div> 

                    <div className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91-9717929729"className="D6_AboveFooter_Icon_H">
                        +91-9717929729 
                        </a>  
                    </div>
                 
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:info@knowledgefocal.com"  className="D6_AboveFooter_Icon_E">
                        info@knowledgefocal.com
                        </a>  
                    </div>
                </div>
            </div>
        </Grid>

    </Grid>   
</Container>  
</div>
</>
    )
}

export default D6_SL_AF
