import React from 'react';
import Top_sectionA from './top_section/top_section';
import WCU from './WCU/WCU';


export default function Aboutus(){
    return(
        <>
        
        <Top_sectionA />
        <WCU />
        </>
    )
}