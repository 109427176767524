import React from "react";
import './legal.css';
import Top_section_Legal from "./top_section/top_section";
import Below_Top_Legal from "./below_top/below_top";

export default function Legal_Drafts(props){
    const {pricing}=props
    return(
        <div className="bg_sub_legal">
<Top_section_Legal/>
<Below_Top_Legal pricing={pricing}/>
        </div>
    )
}