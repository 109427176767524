import { BrowserRouter,Route,Routes } from 'react-router-dom';
import './App.css';
import Dec6_ScrollNPT from './components/common/STTP/scroll_Top';
import Header from './components/common/header/header';
import Homepage from './components/homepage/homepage';
import D6_SL_AF from './components/common/footer/D6_SL_AF';
import D6_SL_Footer from './components/common/footer/D6_SL_Footer';
import Aboutus from './components/aboutus/about';
import STTopARROW from './components/common/Scroll_To_Top_Arrow/PP_STTop';
import Services from './components/services/services';
import Articles from './components/articles/articles';
import ContactUS from './components/contactus/contactus';
import OPC from './components/services/subServices/OPC/opc';
import TBI_PrivacyPolicy from './components/privacypolicy/TBI_PrivacyPolicy';
import TBI_Term from './components/privacypolicy/TBI_Terms';
import SignIN from './components/authentication/signin/signin';
import Private_Limited from './components/services/subServices/PrivateLimited/privateLimited';
import Public_Limited from './components/services/subServices/PublicLimited/publicLimited';
import Legal_Drafts from './components/services/subServices/LegalDrafts/legalDrafts';
import Policies_Drafts from './components/services/subServices/PoliciesDrafts/policiesDrafts';
import NBFC from './components/services/subServices/NBFC/legalDrafts';
import Dashboard from './components/dashboard/dashboard';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';

// export const live=' http://127.0.0.1:8000/'
// export const live='http://192.168.1.6:8000/'
export const awsPath='https://d6-kfocal.s3.ap-south-1.amazonaws.com/'

export const live='https://d6-kfocal.herokuapp.com/'

function App() {
  const [articles,setArticles]=useState('')
  const [pricing,setPricing]=useState('')

useEffect(() => {
  
fetch(live+'articles-list').then((resp)=>resp.json()).then(res=>articlesRes(res))

},[])

useEffect(() => {
  
  fetch(live+'pricing-list').then((resp)=>resp.json()).then(res=>pricingRes(res))
  
  }, [])

const articlesRes=(val)=>{
setArticles(val)
}
const pricingRes=(val)=>{
  setPricing(val)
  }


if(pricing&&articles){
  return (
    <BrowserRouter>
    <Dec6_ScrollNPT />
    <Header />
    <Routes>
      <Route  path="/" element={<Homepage  pricing={pricing}/>}  /> 
      <Route  path="/aboutus" element={<Aboutus />}  /> 
      <Route  path="/compliance-management/one-person-company" element={<OPC pricing={pricing}/>}  /> 
      <Route   path="/compliance-management/private-limited-company" element={<Private_Limited pricing={pricing} />}  /> 
      <Route  path="/compliance-management/public-limited-company" element={<Public_Limited pricing={pricing} />}  /> 
      <Route  path="/compliance-management/legal-drafts" element={<Legal_Drafts pricing={pricing} />}  /> 
      <Route  path="/compliance-management/draft-policies" element={<Policies_Drafts pricing={pricing} />}  /> 
      <Route  path="/compliance-management/nbfc" element={<NBFC pricing={pricing} />}  /> 
  
      <Route  path="/compliance-management" element={<Services pricing={pricing} />}  /> 
  
      <Route  path="/articles" element={<Articles articles={articles} />}  /> 
      <Route  path="/contactus" element={<ContactUS />}  /> 
  
      <Route  path="/PrivacyPolicy" element={<TBI_PrivacyPolicy />}  /> 
      <Route  path="/Terms&Conditions" element={<TBI_Term />}  /> 
      <Route  path="/signin" element={<SignIN />}  /> 
      <Route  path="/dashboard" element={<Dashboard />}  /> 
  
      </Routes>
    {window.location.pathname==='/dashboard'?null:<>  <D6_SL_AF />
      <D6_SL_Footer /></>}
      <STTopARROW />
  
      </BrowserRouter>
    );
}
else{
  return(
  <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
  <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
  </Grid>)
}

}

export default App;
