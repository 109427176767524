import { Container, Grid, Typography ,Chip} from "@mui/material";
import './mainServices.css'
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import React,{useRef,useEffect,useState} from "react";


import { serviceData, serviceDataCombo } from "./serviceData";
import Payment from "../../payment/payment";
import SignIND from "../../authentication/signin/signInD";
export default function Main_services(props){
  const{pricing}=props
  const myRef = useRef(null)

  const pricing1=pricing.filter((val)=>val.category.includes('Compliance Management-Individual Products'))
  const pricing2=pricing.filter((val)=>val.category.includes('Compliance Management-Combo Products'))


  const [open, setOpen] = React.useState(false);
  const[auth,setAuth]=useCookies(['auth-kf'])
const [payment,setPayment]=useState(false)
const [price,setPrice]=useState('')
const [plan,setPlan]=useState('')
  const handleClose = () => {
    // console.log('success')
    setOpen(false);
};

const payClicked=(val,val1)=>{
    if(auth["auth-kf"]){
        setPrice(val)
        setPlan(val1)
        setPayment(true)
    }
    else{

        setOpen(true)
    }
}

const backClicked=()=>{

  
    setPayment(false)

}
useEffect(()=>{
  if(myRef&&myRef.current)
  myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
  
},[payment])
return(
  
    <>
      <Container>
        <Grid container className="mainContS" style={{marginBottom:30}}>
            <Grid item sm={12} xs={12}>
                <Typography className="services_mainHead">
                {/* Our Optimum Combination Services */}
                COMPLIANCE MANAGEMENT - COMBO PRODUCTS

                </Typography>
            </Grid>
           {pricing2.sort((a,b)=>a.id>b.id?1:-1).map((val,idx)=>
                   <Grid  key={idx} className="sub_cont1S" container sm={6} xs={12}>
                   <Grid className="sub_contS" container sm={12} xs={12}>
                       <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
                       <i class="fas fa-file-invoice icon_services"></i>  
                       </Grid>
                       <Grid item sm={12} xs={12}>
                          <Typography  className="textS_head">
                            {val.planName}
                            </Typography>
                       </Grid>

                       <Grid item sm={12} xs={12}>
                   <Typography className="textS_text">
{val.description}                   
{/* <span  onClick={()=>window.location.href=`${val.url}`} style={{color:'var(--redColor)',paddingLeft:5}}>Read more</span> */}
                   </Typography>
                </Grid>
                <Grid item  style={{justifyContent:'center',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex'}}>
                        <Typography className="strike_amount" >
                            ₹{val.price}
                        </Typography>
                        <Typography className="discount" >
                       {val.discount}% OFF              
                     </Typography>
                    </div>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
                </Grid>
                       </Grid>
                   </Grid>) 
   }
          
         
            </Grid>

    </Container>
    <Container>
    <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
        <Grid container className="mainContS" style={{marginBottom:30}}>
            <Grid item sm={12} xs={12}>
                <Typography className="services_mainHead">
                {/* Knowledge Focal is ready to help you kick-start your business with the following services  */}
                COMPLIANCE MANAGEMENT - INDIVIDUAL PRODUCTS
                </Typography>
            </Grid>
          {pricing1.sort((a,b)=>a.id>b.id?1:-1).map((val,idx)=><Grid key={idx} className="sub_cont1S" container sm={4} xs={12}>
            <Grid  className="sub_contS" container sm={12} xs={12}>
                <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
                <i class="fas fa-file-invoice icon_services"></i>  
                </Grid>
                <Grid item sm={12} xs={12}>
                   <Typography className="textS_head">
                  {val.planName}
                   </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                   <Typography className="textS_text">
                  {val.description}
                   <span onClick={()=>window.location.href=`${val.url}`} style={{color:'var(--redColor)',paddingLeft:5}}>Read more</span>
                   </Typography>
                </Grid>
                <Grid item  style={{justifyContent:'center',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex'}}>
                        <Typography className="strike_amount" >
                            ₹{val.price}
                        </Typography>
                        <Typography className="discount" >
                        {val.discount}% OFF              
                     </Typography>
                    </div>
                  
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
                  
                </Grid>

                </Grid>
            </Grid>)  
          }
           
        </Grid>  

    </Container>
  

        {payment&&price&&plan?<div ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></div>:  null}

    <Container style={{maxWidth:'100%',textAlign:'-webkit-center'}}>


{/* <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your query here..            
          </Typography>
          <D6_DASAC_Form  />
        </DialogContent>
      </Dialog> */}

        {/* <Grid container style={{maxWidth:1200}} className="contact_block"  sm={12} xs={12}>   
<Grid item sm={10} xs={12}> 
            <Typography className="contact">
            Looking for Professional Approach and Quality Services ?  </Typography>
            </Grid> 
            <Grid item sm={2}  xs={12}> 
            <Chip className="contact_chip" label='Contact Us Today' />
            </Grid> 
            
                  </Grid> */}
                  </Container>
    </>
)
}