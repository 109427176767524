import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';


const TBI_PrivacyPolicy = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid1">
                <h3 className="TBI_PrivacyPolicy_Block_MH TBI_PrivacyPolicy_Block_H">
                Privacy Policy
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                Knowledge Focal Educare Private Limited, knowledgefocal.com and its affiliates (collectively, “our,” “us,” or “we”) operate websites, provide Compliance Management services, relevant laws, Modules, Drafts and Trackers thereof, through websites, mobile and other applications, and develop software. We refer to these as “sites,” “services,” or “our sites and services.”                 </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                This privacy policy applies to our sites and services on which we display or post a direct link to this privacy policy. This privacy policy does not apply to those sites and services that do not display or link to this privacy policy, or that have their own privacy policies.   </p>
            </Grid>
          
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            This privacy policy applies to all users and visitors of our sites and services and covers what information we collect and why we collect it, how we use the information we collect, and the choices you have to access and update that information. By using our sites and services, you expressly consent to our collection, use, disclosure, and retention of your personal information as described in this privacy policy. Each time you use our sites and services, the current version of this privacy policy will apply. By visiting, registering or using the Services, you consent to the data practices described in this policy. If you do not agree to this privacy policy, please do not use our sites or services and avoid our sites and services.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 We collect your personal information such as your name, qualification, designation, occupation, contact no., email Id, address, Organisation name with which you are working, if applicable etc. for providing the desired products to you. We also use your personal information to help us communicate or to correspond with you, to tell you about exciting offers, which we think you will value. Customize the content and advertising that you see on knowledgefocal.com or on any of its sub-domains to improve your experience.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 We does not rent, sell, or share personal information about you with other people.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 If you tell us that you do not want us to use this information as a basis for further contact with you, we will respect your wishes. We intend to protect the quality and integrity of your personally identifiable information.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 You may choose to publicly disclose personal information about yourself in the course of (i) contributing user generated content (for instance articles, blogs etc.). Any personal information you submit in the course of interacting in these public activities can be read, collected, or used by other users. We are not responsible for the personal information you choose to make public in any of these areas.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 We encourage you to keep your personal information up-to-date and accurate.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 We makes no representations whatsoever about any other website which you may access through the link provided as part of the Ad or any other way or other links to this site. We make very sincere efforts to respond in a timely manner to your requests to correct inaccuracies in your information, which you have provided to us.                   </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 We sometimes collect anonymous information from visits to our sites to help us provide better customer service. For example, we keep track of the IPs from people who visit our sites and we also measure visitor activity on knowledgefocal.com or any of its sub-domains. We or our affiliates or vendors may use these data to analyse trends and statistics and to help us provide better customer service. We maintain the highest levels of confidentiality for such information; our affiliates and vendors follow the same high levels of confidentiality. This anonymous information is used and analysed only at an aggregate level to help us understand trends and patterns.                  </Grid>
                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                 We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law.                
                   </Grid>
                   <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                   We reserves the right to use the information to provide the user a more personalized online experience. Knowledgefocal.com and its sub-domains provide links to web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that we are not responsible for the availability of any content provided on, third party web sites. The User is requested to peruse the policies posted by other web sites regarding privacy and other topics before use.                    </Grid>
                   <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                   We are not responsible for third party content accessible through the Site, including opinions, advice, statements and advertisements, and user shall bear all risks associated with the use of such content. We are not responsible for any loss or damage of any sort user may incur from dealing with any third party.                    </Grid>
                   <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                   We may revise this privacy policy from time to time and, if we do, we will update it on this page and modify the “Last Updated” date. If our information practices change in a significant way, you will be provided notice by means of notice on our sites or otherwise in writing. Because our privacy policy can change at any time, we encourage you to reread it periodically to see if there have been any changes that affect you.                    </Grid>
                   <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
                   If you have any questions or concerns about this privacy policy or our practices you can send an email to <b>info@knowledgefocal.com      </b>               </Grid>
         
       
       
       </Grid> </div>

    

    )
}

export default TBI_PrivacyPolicy
