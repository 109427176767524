import React from "react";
import './private.css';
import Top_section_Private from "./top_section/top_section";
import Below_Top_Private from "./below_top/below_top";

export default function Private_Limited(props){
    const {pricing}=props
    return(
        <div className="bg_sub_private">
<Top_section_Private/>
<Below_Top_Private pricing={pricing}/>
        </div>
    )
}