import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';


const TBI_Term = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            
              <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
             1. Acceptance of Terms of Use    
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            The portal is offered to you conditioned on your acceptance without any reservation to or modification of the terms, conditions and notices contained herein. By accessing and using the portal and its Services, you accept and agree to be bound by the terms and provisions of the Terms of Use. 
                          </Grid>


                          <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
             2.Use of Portal    
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            The portal is designed, developed and hosted by Knowledge Focal Educare Private Limited (Company). The information and publications available on this portal are broad guides to aspects of legislation and the work of the Company. These are solely intended to provide an understanding of the subject matter and to help the user to assess, monitor and deliver the compliances of applicable laws and services related thereto. <br /><br />
            Best efforts have been made to ensure that the best and widest possible information/services are made available.  
                                      </Grid>
                          <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
             3. Your Registration obligations and Representations to the Company    
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            In regards of your use of the services of the portal, you accept that you are of legal age to form a binding contract and are not a person barred from receiving the services under the laws of any applicable jurisdiction. You further agree on the warrant and covenant that: <br /><br />
            (a) you will not use the services in a way that would: (i) restrict or inhibit any other user from using and enjoying the services, (ii) be illegal, (iii) constitute or encourage conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate applicable laws or regulations, (iv) violate, plagiarize or infringe the rights of third parties including, without limitation, copyright, trademark, patent, rights of privacy or publicity or any other proprietary right, (v) introduce or propagate a virus or other harmful component, (vi) contain any information, software or other material of a commercial nature, (vii) contain advertising of any kind or any deceptive online marketing, (viii) constitute or contain false or misleading indications of origin or statements of fact; or (ix) violate the Acceptable Use Policies of any service or technology accessed through the portal: (b) that you are at least eighteen (18) years old; and (c) that all information submitted in connection with your registration is and will remain accurate and complete. Company is concerned about the safety and privacy of all its users. You agree to notify the Company immediately of any impending or actual breach of security or other event known to you that may negatively affect services provided by the Company. 
            
                                      </Grid>


                           <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
             4. Modification   
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            Company reserves the right to change the terms, conditions and notices under which this portal is offered, including but not limited to the charges associated with the use of the portal.                           </Grid>
                          <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
             5. Disclaimer   
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            The information/material and any downloads made available on this portal is provided or made available on as is basis. The information provided by this portal is the property of Knowledge Focal Educare Private Limited. The information contained in this portal is merely directional. This portal is not a substitute for independent professional and/or legal advice. This portal and/or any part thereof does not constitute legal advice and does not establish an attorney-client relationship. If you need legal advice, always contact a legal practitioner/attorney directly. Though Knowledge Focal Educare Private Limited makes reasonable efforts to ensure that the information provided on this portal is accurate, but does not guarantee or warranty its suitability for any specific purpose. Moreover the templates have been provided in word so that the user can modify and customise them as per their purpose. 
            <br/><br/>
            Knowledge Focal Educare Private Limited disclaims all responsibilities for any loss, injury, liability or damage of any kind resulting from and arising out of, or any way related to, without limitation; 
            <br/><br/>
           (i) Any errors in or omissions from this portal and its content, including but not limited to technical inaccuracies and typographical errors. 
           <br/><br/>
(ii) Any third party websites or content therein directly or indirectly accessed through links in this portal, including but not limited to any errors in or omissions therefrom.  
                                      </Grid>
                          <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                          6. General practices regarding use and storage    
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            You acknowledge that Company may establish general practices and limits concerning use of the portal, including without limitation the maximum number of transfers or downloads that may be processed by an account on it, the maximum number of times (and the maximum duration for which) you may access the portal in a given period of time. You acknowledge that the Company reserves the right to log off accounts that are inactive for an extended period of time. You further acknowledge that Company reserves the right to modify these general practices and limits from time to time.                                       </Grid> 

                       <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                          7. Links to Third Party    
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            The portal may contain links to other websites (“Linked Sites”). The link sites are not under the control of the Company. Therefore, Company is not responsible for the contents and/or reliability of linked websites, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site and does not necessarily endorsed the view expressed within them.  
                                      </Grid>             

                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                          8. Copyright   
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            Distribution and exchange of information provided on this portal is not allowed. Company owns the copyright in all material (except pdf. Documents). This portal and all the contents in this portal should not be reproduced, transferred, copied, republished, uploaded, posted, transmitted, distributed, exchanged or used for the creation of derivative works without the Companies prior written consent. The information on portal to be solely for professional use by the registered user.                                        </Grid>
                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                          9. Liability    
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            Under no circumstances shall Company be liable to any user for: A. Loss, injury, claim, liability or damages of any kind resulting from the use of or the inability to use the materials in the portal, even if the Company had been advised of the possibility of such damages and regardless of the form of action whether in contract, tort or otherwise. B. Special, Direct, Incidental, Punitive, Exemplary or consequential damages of any kind whatsoever (including without limitation, attorneys’ fees) in any way due, resulting from or arising in connection with the use of or the inability to use the portal or its contents/information and C. claims attributable to errors, omission or inaccuracies in or destructive properties of any information. Company makes reasonable efforts to ensure that the information provided on the portal is accurate but does not guarantee or warrant its suitability for any specific purpose.                                       </Grid>
                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                                                 10. Indemnification             </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            You agree to indemnify, defend and hold harmless the Company and its affiliates and their officers, directors, employees, contractors, agents, licensors, service providers, sub-contractors and suppliers from and against any and all losses, liabilities, expenses, damages and costs, including attorneys’ fees, court costs arising or resulting from your use of the portal and any violation of these terms of use. If you cause any technical disruption of the portal or the systems transmitting the portal to you or others, you agree to be responsible for any and all losses, liabilities, expenses, damages and costs, including reasonable attorneys’ fee, court costs, arising or resulting from that disruption.                                       </Grid>
                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                                                 11. Termination or Access Restriction     
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            Company reserves the right, in its sole discretion, to terminate your access to this portal and the related services or any portion thereof at any time, without notice.                                       </Grid>
                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                                                 12. Applicable Laws             </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            This agreement shall be governed by the laws of India and you hereby consent to the exclusive jurisdiction of Courts in New Delhi, India in all disputes arising out of or relating to the use of this portal.                                       </Grid>
                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                                                 13. Severability    
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            If any of the provisions of this agreement or part thereof is rendered void, illegal or unenforceable in any respect under any law, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.                                       </Grid>
                                                 <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
                                                 14. Acceptance to receive updates             </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            By subscribing to this portal you have permitted Knowledge Focal Educare Private Limited to send you the notifications, updates, news on your registered email and contact number.                                       </Grid>
                                     
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid_BoldText TBI_PrivacyPolicy_Block_P">
            15. Refund, Return and Cancellation 
</Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid5 TBI_PrivacyPolicy_Block_P">
            Being we are dealing in downloadable services (Compliance Management modules, trackers, respective laws and drafts), Refund, Return and Cancellation are not applicable and hence we need not to have any specific policy for this.                                       </Grid>
       </Grid> </div>

    

    )
}

export default TBI_Term
