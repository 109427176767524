import React from "react";
import './policies.css';
import Top_section_Policies from "./top_section/top_section";
import Below_Top_Policies from "./below_top/below_top";

export default function Policies_Drafts(props){
    const {pricing}=props
    return(
        <div className="bg_sub_policies">
<Top_section_Policies/>
<Below_Top_Policies pricing={pricing}/>
        </div>
    )
}