import { CircularProgress, Container, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './documents.css'
// import { docs, Plans } from "./data";
import { useEffect } from "react";
import {useCookies} from 'react-cookie'
import {live,awsPath} from '../../../App'
export default function Documents(){
const [plans,setPlans]=useState([])
const [docs,setDocs]=useState([])
const [processing,setProcessing]=useState(false)

const[auth,setAuth]=useCookies(['auth-kf'])


useEffect(() => {

  if(auth["auth-kf"]){
    setProcessing(true)
    fetch(`${live}plan-purchased?q=${auth["auth-kf"].id}`).then((res)=>res.json()).then(resp=>plansRes(resp))
  }
 
}, [])
useEffect(() => {
  setProcessing(true)

  if(auth["auth-kf"]){
    fetch(`${live}document-list`).then((res)=>res.json()).then(resp=>docRes(resp))
  }
 
}, [])

const plansRes=(res)=>{
  if(res){
    setProcessing(false)
    setPlans(res)
  }

}
const docRes=(res)=>{
  if(res){
    setDocs(res)
  }

}







const downloadClicked=(val,val2)=>{
 
        
            let alink = document.createElement('a');
            alink.href = awsPath+val.url;
            alink.download = val.doc_name+'.'+val2;
            alink.click();
  
 
}

if(plans[0]&&docs[0] &&!processing)
{
    return(
<Container>
    <Grid conatiner className="acc_doc">
  {plans.map((plan,idx)=>
 <Accordion >
 <AccordionSummary
   expandIcon={<ExpandMoreIcon />}
   aria-controls="panel1a-content"
   id="panel1a-header"
 >
   <Typography className="head_doc">Documents For {plan.planName} Plan</Typography>
 </AccordionSummary>

 {

 }
 <AccordionDetails>
 <Accordion>
 <AccordionSummary
   expandIcon={<ExpandMoreIcon />}
   aria-controls="panel1a-content"
   id="panel1a-header"
 >
   <Typography className="text_doc">Checklist</Typography>
 </AccordionSummary>
 <AccordionDetails>
 <Grid  container>
{docs.filter((val)=>(val.planName.includes(plan.planName)&&val.doc_type==='Checklist')).map((val,idx)=>

<Grid key={idx} item sm={3} xs={6}>

<Paper onClick={()=>downloadClicked(val,'xlsx')} className="ppr_documents">
{val.doc_name}
</Paper>
</Grid>
)      
}</Grid>


 </AccordionDetails>
</Accordion>
<Accordion>
 <AccordionSummary
   expandIcon={<ExpandMoreIcon />}
   aria-controls="panel1a-content"
   id="panel1a-header"
 >
   <Typography className="text_doc">Trackers</Typography>
 </AccordionSummary>
 <AccordionDetails>
 <Grid  container>
{docs.filter((val)=>(val.planName.includes(plan.planName)&&val.doc_type==='Tracker')).map((val,idx)=>

<Grid key={idx} item sm={3} xs={6}>

<Paper onClick={()=>downloadClicked(val,'xlsx')} className="ppr_documents">
{val.doc_name}
</Paper>
</Grid>
)      
}</Grid>


 </AccordionDetails>
</Accordion>

{
 [... new Set(docs.filter((val,idx)=>(val.planName.includes(plan.planName)&& val.category !='Main')).map(data=>data.category))].map((val,idx)=>
 
 <Accordion>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1a-content"
id="panel1a-header"
>
<Typography className="text_doc">{val}</Typography>
</AccordionSummary>
<AccordionDetails>
<Accordion>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1a-content"
id="panel1a-header"
>
<Typography className="text_doc">Draft</Typography>
</AccordionSummary>
<AccordionDetails>
<Grid container>
{docs.filter((val1,idx)=>((val1.planName.includes(plan.planName))&&(val1.category===val)&&(val1.doc_type==='Draft'))).map((val,idx)=>
<Grid key={idx} item sm={3} xs={6}>

<Paper className="ppr_documents" onClick={()=>downloadClicked(val,'docx')}>
{val.doc_name}
</Paper>
</Grid> )
}

</Grid>
</AccordionDetails>
</Accordion>
<Accordion>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1a-content"
id="panel1a-header"
>
<Typography className="text_doc">Laws</Typography>
</AccordionSummary>
<AccordionDetails>
<Grid container>
{docs.filter((val1,idx)=>((val1.planName.includes(plan.planName))&&(val1.category===val)&&(val1.doc_type==='Law'))).map((val,idx)=>
<Grid key={idx} item sm={3} xs={6}>

<Paper className="ppr_documents" onClick={()=>downloadClicked(val,'pdf')}>
{val.doc_name}
</Paper>
</Grid> )
}
</Grid>



</AccordionDetails>
</Accordion>
</AccordionDetails>
</Accordion>
 )






}



<Accordion>
 <AccordionSummary
   expandIcon={<ExpandMoreIcon />}
   aria-controls="panel1a-content"
   id="panel1a-header"
 >
   <Typography className="text_doc">Download All</Typography>
 </AccordionSummary>
 <AccordionDetails>
<Grid container>
{docs.filter((val1,idx)=>(val1.planName.includes(plan.planName)&&val1.doc_type==='Zip')).map((val,idx)=>
<Grid key={idx} item sm={3} xs={6}>

<Paper className="ppr_documents" onClick={()=>downloadClicked(val,'zip')}>
{val.doc_name}
</Paper>
</Grid> )
}
</Grid>



</AccordionDetails>
</Accordion>
 </AccordionDetails>
</Accordion> )  }




    </Grid>
</Container>
    )}
    if(processing){
      return(
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
        <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
        </Grid>)
    }
    else{
      return(
        <Grid sm={12} style={{textAlign:'center'}} className='acc_doc' xs={12} item>
           <Typography className="head_doc">No Documents Found</Typography>

        </Grid>)
    }
}