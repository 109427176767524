import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography ,Grid,Paper} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useCookies} from 'react-cookie'

import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from '../../images/kfocal.svg'
import MenuIcon from '@mui/icons-material/Menu';
import './dashboard.css'
import Documents from './documents/document';
import Profile from './profile';
import PaymentsInvoice from './payments';
import DataGridUserInfo from './userAdmin';

export default function Dashboard(props) {

  const[auth,setAuth]=useCookies(['auth-kf'])

 



  const [state, setState] = React.useState({
 
    left: false,
   
  });
  const [screen,setScreen]=React.useState('Dashboard')
  const [anchor]=React.useState('left')

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <Divider />
      <List>
        {(auth['auth-kf'].isAdmin?['My Profile', 'My Documents', 'Payments',"User Admin"]:['My Profile', 'My Documents', 'Payments']).map((text, index) => (
          <ListItem key={text} onClick={()=>setScreen(text)} disablePadding>
            <ListItemButton>
             
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

if(!auth['auth-kf'])(
  window.location.href='/signin'

)
else{


  return (
    <div>

 <React.Fragment >
          <CssBaseline />
      <AppBar
        position="fixed" 
        className="appbarMain"
       style={{top:120}}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
              <div >
            <IconButton
             onClick={()=>setState({...state,left:!state.left})}
                color="inherit"
                aria-label="open drawer"
                
                edge="start"
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{alignSelf:'center'}} >
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              <div onClick={()=>window.location.href='/'} style={{width:'100%',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                HOME
              </div>
              </div>
        </Toolbar>
      </AppBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}

          >

<Grid container  >
            <Grid style={{textAlign:'center'}} item xs='12'>
            <img src={Logo} style={{maxWidth:'100%'}} />
              </Grid>
              <Grid  style={{textAlign:'center'}} item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto',fontSize:'16px',paddingLeft:18,textAlign:'initial'}}>Hey <b style={{color:'var(--redColor)'}}>{auth['auth-kf'].name}</b></h1>
              </Grid>

            
          <Grid item style={{alignSelf:'center',textAlign:'center'}} xs='4'>
          <IconButton               onClick={()=>setState({...state,left:!state.left})}
>
            {state.left ? <ChevronLeftIcon style={{fontSize: 40}} /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
                   
            {list(anchor)}
          </Drawer>
          <main
        style={{minheight:'50vh'}}
      >
        
   {screen==='My Documents'?<Documents />:screen==='User Admin'?<DataGridUserInfo />:screen==='My Profile'?<Profile />:screen==='Payments'?<PaymentsInvoice />: <div style={{height:'100%'}}>
      <Grid className='grid_contPPr' container>
      <Grid item    sm={3} xs={6}>
    <Paper elevation={6} onClick={()=>setScreen('My Profile')} className='dash_sub'>
        My Profile
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('My Documents')}  className='dash_sub'>
        My Documents
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Payments')}  className='dash_sub'>
        Payments
        </Paper>
        </Grid>
      {auth&&auth['auth-kf'].isAdmin?  <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('User Admin')}  className='dash_sub'>
        Admin
        </Paper>
        </Grid>:null}
   
  
        </Grid>
    </div>}
   
   

      </main>
        </React.Fragment>
     
      
      
    </div>
  );}
}
