import React from "react";
import Below_Top_OPC from "./below_top/below_top";
import './opc.css'
import Top_section_OPC from "./top_section/top_section";
export default function OPC(props){
    const {pricing}=props
    return(
        <div className="bg_sub_opc">
<Top_section_OPC />
<Below_Top_OPC pricing={pricing} />
        </div>
    )
}