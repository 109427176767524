import { Chip,Typography } from '@mui/material';
import React from 'react';
import './top_section.css'
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import D6_DASAC_Form from '../../common/footer/D6_SL_AF_Form';
export default function Top_sectionA(){
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return(
        <div >
            <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your query here..            
          </Typography>
          <D6_DASAC_Form  />
        </DialogContent>
      </Dialog>
            <div className='topA_bg'  />
            <div className='bg_head'>
                <h1>

                Participating in building Good Corporate Governance

                </h1>

            </div>
            <div className='topA_SubImg'>
            <div className='contA_sub'>
            <div className='bg_head2'>
                <h2>
                Imagine a Corporate World where all the Corporates are fully compliant in their respective Domain
               </h2>
                {/* <h1>
                Our Motto is to provide everyone with reliable legal forms and precedents in an easy and affordable manner.
                </h1> */}
                <p>
                Isn’t this statement fascinating? Yes indeed it is. Knowledge Focal Educare Private Limited (KnowledgeFocal.com) is committed in bringing this into a reality by transforming the current Compliance Management System into a more viable and user friendly interface. 

 <br /> <br />
<span style={{color:'var(--redColor)',fontWeight:'600'}}>
 KnowledgeFocal.com is providing ready drafts along with decoding of various provisions of relevant sections of applicable Acts, compliance modules, trackers, which will add spine in ease of doing business to corporate houses including Startups.
 </span><br />  <br />
 Moreover, our professional brothers and sisters occupies a pivotal & fundamental role in today’s economy and so it requires him/her to sharpen the required substance knowledge on real time basis. Sometime it requires the professionals to deliver the given task in no time or even in a time line which is quite challenging considering the proficiency required in the assigned task. Here also, KnowledgeFocal.com facilitate them through its Products in completing their tasks in no time and with sharp accuracy, thereby increasing the focus and reducing the risk of non-compliance and hence creating the<span style={{color:'var(--redColor)',fontWeight:'600'}}> VALUE</span>.
<br /> <br />
 

Come and join the journey of making a world, we all dream about.     
                </p>

                {/* <Chip  label='Contact Us'  onClick={()=>setOpen(true)} className='chip_about'/> */}

            </div>
            </div>
            </div>
        </div>
    )
}