import { Chip, Container, Grid, Typography } from "@mui/material";
import React,{useRef,useEffect,useState} from "react";
import  '../../OPC/below_top/below_top.css'

import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import SignIND from "../../../../authentication/signin/signInD";
import Payment from "../../../../payment/payment";
import { serviceData } from "../../../mainServices/serviceData";

export default function Below_Top_Private(props){
    const {pricing} =props
    const serivcesParticular=pricing.filter((val)=>val.planName=='Private Limited Company')
  
    const [open, setOpen] = React.useState(false);
    const[auth,setAuth]=useCookies(['auth-kf'])
  const [payment,setPayment]=useState(false)
  const [price,setPrice]=useState('')
  const [plan,setPlan]=useState('')
    const handleClose = () => {
      // console.log('success')
      setOpen(false);
  };
  const myRef = useRef(null)

  useEffect(()=>{
      if(myRef&&myRef.current)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
      
    },[payment])
  
  const payClicked=(val,val1)=>{
      if(auth["auth-kf"]){
          setPrice(val)
          setPlan(val1)
          setPayment(true)
      }
      else{
  
          setOpen(true)
      }
  }
  
  const backClicked=()=>{
  
    
      setPayment(false)
  
  }
    return(
        <>
     <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
        <Grid container className="bt_OPC">
        <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Introduction
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

Private Company is defined under Section 2(68) of the Companies Act, 2013 as<b> “Private Company”</b> means a company having a minimum paid-up share capital as may be prescribed, and which by its articles: <br /><br />
<span style={{fontStyle:'italic'}}>
(i) restricts the right to transfer its shares;  <br /><br />

(ii) except in case of One Person Company, limits the number of its members to two hundred:   <br /><br />
</span>
<b>Provided that </b>where two or more persons hold one or more shares in a company jointly, they shall, for the purposes of this clause, be treated as a single member:   <br /><br />

<b>Provided further that</b>— <br /><br />

(A) persons who are in the employment of the company; and <br /><br />

(B) persons who, having been formerly in the employment of the company, were members of the company while in that employment and have continued to be members after the employment ceased, 

shall not be included in the number of members; <br /><br />

<span style={{fontStyle:'italic'}}>(iii) prohibits any invitation to the public to subscribe for any securities of the company; </span><br /><br />
 

Private Limited Company is a most popular option to start a business in India by startups and businesses with higher growth aspirations. It can be registered with minimum of two directors and subscribers/shareholders. A person can be both a director and shareholder in a Private Limited Company. The liability of the shareholders/members of a Private Limited Company is limited to the extent of unpaid amount of shares held by them.<br /><br />
                        </Typography>
</Grid>
{serivcesParticular.map((val,idx)=>
    <Grid item  key={idx} style={{justifyContent:'start',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography className="strike_amount" >
                         {val.price}
                        </Typography>
                        <Typography className="discount" >
                        {val.discount}% OFF              
                     </Typography>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
    
                  </div>

                </Grid>) 
 }






                    </Grid>
                    <Grid container style={{paddingTop:'10vh'}} sm={5} xs={12}>
                    <img className="imgsub_OPC" src={(require('../../../../../images/private-side.jpg'))} />
                    </Grid>
                </Grid>
            </Container>


            <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={12} xs={12}>

<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head_UL">
    Characteristics
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    A Private Limited Company is a separate legal identity in the eyes of the law.
    </li>
    <li className="li_OPC">
    If the company undergoes financial distress, the personal assets of members/shareholders cannot be used for paying the debts of the Company
        </li>
        <li className="li_OPC">
        The shareholders assign responsibility to operate and run the company without losing control in the form of voting.         </li>
        <li className="li_OPC">
        A private limited company must have a minimum of two members, while the maximum members limit is 200.         </li>
        <li className="li_OPC">
        A private limited company must have a minimum of two directors, while the maximum number of directors is 15.However, the maximum limit of directors can be increased beyond 15 by passing a special resolution.             </li>
            <li className="li_OPC">
            The private limited company must have an authorised share capital of Rs.1 lakh. Due to amendment to the Companies Act, it need not have any minimum paid-up share capital            </li>
</ul>
</Grid>




<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head_UL">
    *Private Limited Company in statistic
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    12,33,768 Private Limited Companies are active in India as on March 31, 2021.     </li>
    <li className="li_OPC">
    More than 1,43,000 Private Limited Companies were registered during the financial year 2020-21.         </li>
        <li className="li_OPC">
        Private Limited Companies have their presence in every sector of economy be it Agriculture, Manufacturing, Construction, Electricity, Gas, Transportation, Communication, Mining, Community and Social Services, Finance, Trading, Insurance and what not.         </li>
        
</ul>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className="stat_text">
    *Statistics from 7th Annual Report on the working and administration of the Companies Act, 2013 year ended on March 31, 2021, Ministry of Corporate Affairs, Government of India.     </Typography>
</Grid>





<Grid item sm={12} xs={12}>
    <Typography style={{width:'100%'}} className="bt_OPC_head_UL">
    Privileges to Private Limited Company (including but not limited to the following)     </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    The private Limited Company are not required to comply with the procedures of providing the facility of remote voting to its members.     </li>
    <li className="li_OPC">
    Provision w.r.t. the retirement of Directors by rotation is not applicable on Private Limited Company.        </li>
        <li className="li_OPC">
        A private Limited Company may by its articles provide for any special disqualifications for appointment as a director in addition to those specified in law     </li>
        <li className="li_OPC">
        A private Limited Company is not required to have an independent director and women director in its Board of Directors.            </li>
        <li className="li_OPC">
        A Private Limited Company is not required to constitute Audit or Nomination and Remuneration Committee (NRC) Committee of the Board.       </li>
        <li className="li_OPC">
        Private Limited Company is not obligated to appoint Key managerial personnel, except where its paid up share capital exceeds 10Cr. in which case the requirement of appointing Whole Time Company Secretary is applicable.               </li>
               <li className="li_OPC">
               In case of right issue under section 62 period of notice can be shortened and private Limited Company may close its offer of rights issue before minimum period of 15 days. In other words, it needs not keep its right issue open for minimum period of 15 days. These benefits are available only if 90% of the member of the Company agrees. 
   
               </li>
        
</ul>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

    <span onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} style={{color:'var(--redColor)'}}>  Click here to download </span> Compliance Module, relevant Laws, Drafts and Trackers for Private Limited Company 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_quote'>

    <span style={{color:'var(--redColor)'}}>“</span>Because there is no alternate to a Good Corporate Governance<span style={{color:'var(--redColor)'}}>”</span> 
    </Typography>
</Grid>
              
                    </Grid>
              
                </Grid>
            </Container>
            {payment&&price&&plan?<Container ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></Container>:  null}

        </Grid>

        </>
    )
}
