
import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import './PP_STTop_CSS.css';
// import "../../MainCSS.css";

const STTopARROW  = () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 200){

      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
     <div className="PP_ST_Div">
        <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'block' : 'none'}}/>
        {/* <FaWhatsapp className="scrollTop1" onClick={()=>window.open("https://wa.me/+917292076869","_blank")} style={{height: 40, display:'block'}}/> */}

      </div>

  
  );
}

export default STTopARROW 
