import React,{useState} from 'react';

import Grid from '@mui/material/Grid';
import './header.css'
import LOGO from "../../../images/kfocalNew.svg"
import Navbar from './navbar';
import { Typography } from '@mui/material';
import TemporaryDrawer from './D6_Sl_Drawer';
function Header() {
       
    const [Dec6_Navbar_Items1,setNavbar] = useState(false);
    const [open, setOpen] = useState(false)
 
    const Toggle=()=>{
      setOpen(!open)
    }
    const changeBackground = () => {
        if(window.scrollY >= 20)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll',changeBackground);

        return(
            <>
            <nav className='header_strip'>
                <Grid style={{height:'100%'}} container>
           <Grid item  className='icon_gridM' sm={3}>
        <img onClick={()=>window.location.href='/'} className='icon_head' src={LOGO}></img>
    </Grid>

    <Grid container sm={9} xs={12} className='Grid_MC'>
 
 <Grid item >
     <Grid onClick={()=>window.open('tel:+91-9717929729')} container style={{alignItems:'center',cursor:'pointer',justifyContent:'center'}}>
     <Grid item >
     <i className="fas fa-phone-alt icon_nav"></i>

     </Grid>
     <Grid item>
         <Typography className='text_icon'>
         +91-9717929729
     </Typography>
     </Grid>
         </Grid>

 </Grid>
 <Grid item >
 <Grid container onClick={()=>window.open('mailto:info@knowledgefocal.com')} style={{alignItems:'center',cursor:'pointer',justifyContent:'center'}}>

     <Grid item>
     <i className="fa fa-envelope icon_nav"></i>

     </Grid>
     <Grid item>
         <Typography className='text_icon'>
         
         info@knowledgefocal.com
     </Typography>
     </Grid>
         </Grid>

 </Grid>
 
 </Grid>  
                </Grid>
             
            </nav>
           {window.location.pathname==='/dashboard'?null: <nav className={Dec6_Navbar_Items1?'D6_SL_Navbar_ItemsA':'D6_SL_Navbar_Items'}>
<Grid container className='Grid_MC'>
    <Grid container className='grid_contSub' sm={3}>
        <Grid container className='Grid_TC' sm={6}>
        <Grid item className='grid_ham'  sm={12}>
        <i class="fas fa-align-justify icon_ham" onClick={()=> setOpen(!open) } ></i>    </Grid>
      <Grid item  className='icon_gridM2' sm={12}>
        <img onClick={()=>window.location.href='/'} className='icon_head' src={LOGO}></img>
    </Grid>
    </Grid>
 
    </Grid>
    <Grid item style={{alignSelf:'center'}} className='grid_multi' sm={12} xs={12}>
    <TemporaryDrawer Toggle={Toggle} open={open}  />
   <Navbar  />
     </Grid>
    
    </Grid>                  
            </nav>}
            </>
        );
    }
    
    export default Header;