import { Container, Grid, Typography,TextField, Button } from "@mui/material";
import React from "react";
import './contactus.css'
export default function ContactUS(){
    const [data,setData]=React.useState({
        name:'',
        email:'',
        phone:'',
        message:'',
        service:'',
      
      
    
    })
    const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)


    const handleSubmit=()=>{

        if(data.name&&data.email&&data.phone&&data.service){
            setProcessing(true)
            const formData=new FormData
            formData.append('name',data.name)
            formData.append('email',data.email)
        
        
            formData.append('phonenumber',data.phone)
            formData.append('message',data.message)
            formData.append('service',data.service)
        
        
        
        
        
        
        
        
            fetch('https://d6apicluster.herokuapp.com/mail-TBI'
            // fetch('http://127.0.0.1:8000/mail-TBI'
            ,
            {
            
              method:'POST',
            
              body:formData
              })
            
              .then(res=>res.json())
              .then((resp)=>MailRes(resp.detail))
            
            
              .catch(error=>console.log(error))
          
        }
        else{
            alert('Please fill all the mandatory fields')
        }
     
        }

        
    const MailRes=(val)=>{
        setProcessing(false)
  setSubmitted(true)
  setData({
  ...data,name:'',email:'',phone:'',message:'',service:''
  })
  setSubmitted(()=>setTimeout(() => setSubmitted(false), 10000))   
  }

    return(
        <>
        <div style={{height:70,background:'var(--blueColor)'}}>

        </div>
        <div className="contact_bg">

        </div>
        <Container style={{textAlign:'-webkit-center'}}>
            <Grid container >
                <Grid item sm={12} xs={12}>
                    <Typography className="contact_head">
                    Get in Touch
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className="contact_subhead">
                    Assisting you Always
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item style={{width:'90%'}} sm={6} xs={6}>
                    <TextField  style={{width:'90%'}}  id="filled-basic" name="name"  value={data.name}   onChange={(e)=>setData({...data,
                      name:e.target.value
                  })} label="Name*" variant="filled" />
                    </Grid>
                    <Grid item sm={6} style={{width:'90%'}}  xs={6}>
                    <TextField id="filled-basic"   onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email} style={{width:'90%'}} label="Email*" variant="filled" />
                        </Grid>
                        <Grid item style={{width:'90%',paddingTop:20}} sm={6} xs={6}>
                    {/* <TextField  onChange={(e)=>setData({...data,
                        service:e.target.value
                    })}  name="service"
                      value={data.service} style={{width:'90%'}} id="filled-basic" label="Service*" variant="filled" />
                    */}

<TextField     onChange={(e)=>setData({...data,
                        company:e.target.value
                    })}  name="company"
                      value={data.company}  id="filled-basic" style={{width:'90%'}} label="Organisation / Firm Name (if any)" variant="filled" />
                     
                    </Grid>
                    <Grid item sm={6} style={{width:'90%',paddingTop:20}}  xs={6}>
                    <TextField     onChange={(e)=>setData({...data,
                        phone:e.target.value
                    })}  name="phonenumber"
                      value={data.phone} id="filled-basic" style={{width:'90%'}} label="Phonenumber*" variant="filled" />
                        </Grid>
                       

                       
                        {/* <Grid item sm={12} style={{width:'90%',paddingTop:20}}  xs={12}>
                    <TextField     onChange={(e)=>setData({...data,
                        company:e.target.value
                    })}  name="company"
                      value={data.company}  id="filled-basic" style={{width:'95%'}} label="Company" variant="filled" />
                        </Grid> */}
                        <Grid item sm={12} style={{width:'90%',paddingTop:20}}  xs={12}>
                    <TextField       name='message'
                        onChange={(e)=>setData({...data,
                            message:e.target.value
                        })}       value={data.message}
                       id="filled-basic" style={{width:'95%'}} label="Message" multiline rows={8} variant="filled" />
                        </Grid> 
                        <Grid item sm={12} style={{width:'90%',paddingTop:20}}  xs={12}>
                    <Button  variant="contained" className="btn_contact" > {
                    submitted&&!processing?'Submitted':processing?'Submitting':'submit'
                    }</Button>
                        </Grid> 

                </Grid>


            </Grid>

            <Grid container style={{marginTop:100,marginBottom:30,textAlign:'center',width:'95%'}}>
                <Grid container sm={6} xs={12}>
                    <Grid item sm={12} xs={12}>
                    <i class="fas fa-mobile iconC"></i>
                    </Grid>                  <Grid item sm={12} xs={12}>
                 <Typography className="iconC_head">
                    Call us
                 </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                 <Typography className="iconC_text">
                 +91-9717929729
                 </Typography>
                    </Grid>
                </Grid>
                <Grid container sm={6} xs={12}>
                    <Grid item sm={12} xs={12}>
                    <i class="fas fa-envelope iconC"></i>
                    </Grid>                  <Grid item sm={12} xs={12}>
                 <Typography className="iconC_head">
                    Email us
                 </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                 <Typography className="iconC_text">
                 info@knowledgefocal.com
                 </Typography>
                    </Grid>
                </Grid>
                {/* <Grid container sm={4} xs={12}>
                    <Grid item sm={12} xs={12}>
                    <i class="fas fa-map-marker iconC"></i>
                    </Grid>                  <Grid item sm={12} xs={12}>
                 <Typography className="iconC_head">
                    Visit us
                 </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                 <Typography className="iconC_text">
                 Netaji Subhash Place, Pitampura, New Delhi
                 </Typography>
                    </Grid>
                </Grid> */}
            </Grid>

        </Container>
        {/* <Container style={{maxWidth:'100%'}}>
        <div className="ContactUs_Map">
            <iframe 
            //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7015.8682294569935!2d77.02272007487564!3d28.45140242995817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d183a70002b83%3A0x2826fe7d22f50571!2sShivaji%20Nagar%2C%20Sector%2011%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1608698517297!5m2!1sen!2sin"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.8846591628585!2d77.14455261492482!3d28.69309668239391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03ccef3754ed%3A0xb1c6a1c497337062!2sVardhman%20Plaza%2C%20209%2F210%2C%202nd%20Floor%2C%20H-3%20Building%2C%20Vardhman%20Plaza%2C%20Netaji%20Subhash%20Place%2C%20Wazirpur%2C%20Delhi%2C%20110034!5e0!3m2!1sen!2sin!4v1659944529339!5m2!1sen!2sin"
              width="100%"
              height="390"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              // loading="lazy"
              tabIndex="0"
            />
        </div>
        </Container> */}
        </>

    )
}