import { Chip, Container, Grid, Typography } from "@mui/material";
import React,{useRef,useEffect,useState} from "react";
import  '../../OPC/below_top/below_top.css'

import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import SignIND from "../../../../authentication/signin/signInD";
import Payment from "../../../../payment/payment";
import { serviceData } from "../../../mainServices/serviceData";

export default function Below_Top_Policies(props){
    const {pricing} =props
    const serivcesParticular=pricing.filter((val)=>val.planName=='Policies Draft')
 
    const [open, setOpen] = React.useState(false);
  const[auth,setAuth]=useCookies(['auth-kf'])
const [payment,setPayment]=useState(false)
const [price,setPrice]=useState('')
const [plan,setPlan]=useState('')
  const handleClose = () => {
    // console.log('success')
    setOpen(false);
};
const myRef = useRef(null)

  useEffect(()=>{
      if(myRef&&myRef.current)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
      
    },[payment])

const payClicked=(val,val1)=>{
    if(auth["auth-kf"]){
        setPrice(val)
        setPlan(val1)
        setPayment(true)
    }
    else{

        setOpen(true)
    }
}

const backClicked=()=>{

  
    setPayment(false)

}
   
   
    return(
        <>
               <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Grid container className="bt_OPC">
        {payment&&price&&plan?<Payment backClicked={backClicked} price={price} plan={plan}/>: 
        <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Introduction
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

The world of corporate compliance uses many terms of art, and one of the most common is “policies.” Yet, somehow, it also ends up being one of the most vague phrases we use, too. This is why even the most seasoned compliance professionals need a refresher on why policies and procedures are important in the workplace.   <br /><br />


Think about it: when was the last time you encountered a compliance issue that didn’t somehow involve better policies? No matter how your corporation operates or the particular problem is haunting you today—when management asks, “How do we assure this doesn’t happen again?” part of the answer inevitably involved better policies and procedures.  

 <br /><br />
 So clearly it’s important, but why do we have policies and procedures? Let’s review.   <br /> <br />
 The purpose of policies and procedures is to bring uniformity to corporate operations, and therefore reduce the risk of an unwanted event. Another way to phrase it: policies and procedures organize employees to behave in certain ways, which lets the business achieve its objectives more efficiently. Sometimes policies and procedures help to achieve operational objectives—say, ways to develop a new product, or how to handle a customer service call. Other policies and procedures help to achieve compliance objectives. <br /> <br/>
       
 Regulatory policies be it from NBFC point of view or from Companies Act, 2013 and other statues are of paramount importance to a business. We have developed the mandatory policies required in corporate world. <br /> <br />
       
       
                        </Typography>
</Grid>
{serivcesParticular.map((val,idx)=>
    <Grid item  key={idx} style={{justifyContent:'start',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography className="strike_amount" >
                         {val.price}
                        </Typography>
                        <Typography className="discount" >
                        {val.discount}% OFF              
                     </Typography>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
    
                  </div>

                </Grid>) 
 }






                    </Grid>
                    <Grid container style={{paddingTop:'10vh'}} sm={5} xs={12}>
                    <img className="imgsub_OPC" src={(require('../../../../../images/policies-side.jpg'))} />
                    </Grid>
                </Grid>
            </Container>}


            <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={12} xs={12}>



                    <Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Importance to update the Policies 
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

Because business operation changes and if you don’t update policies and procedures to keep pace with that change, they’re no longer fit for purpose.<br /><br />


A better way to think about this question is to consider all the ways your business operations change. That gives a sense of all the ways you should assess your policies and procedures to see whether circumstances have changed so much that your policy manual deserves an update. <br /><br />
 <br />
       
       
                        </Typography>
</Grid>










<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

    <span onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} style={{color:'var(--redColor)'}}>  Click here to download </span> Draft Policies 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_quote'>

    <span style={{color:'var(--redColor)'}}>“</span>Because there is no alternate to a Good Corporate Governance<span style={{color:'var(--redColor)'}}>”</span> 
    </Typography>
</Grid>
              
                    </Grid>
              
                </Grid>
            </Container>
            {payment&&price&&plan?<Container ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></Container>:  null}

        </Grid>

        </>
    )
}
