import { Button, Container, Grid,TextField, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import './signin.css';
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
 import Snackbar from '@mui/material/Snackbar';
 import MuiAlert from '@mui/material/Alert';
import { live } from "../../../App";
import {useCookies} from 'react-cookie'
import { useEffect } from "react";

 export default function SignIND(props){
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
    const [data,setData]=useState('')
    const [active,setActive]=useState('signIn')
    const [activeOtp,setActiveOtp]=useState('')
    const [errMsg,setMsg]=useState('')

    const [login,setLogin]=useState('')
    const [loginType,setLoginType]=useState('')
    const [otp1,setOTP1]=useState('')
    const [otp2,setOTP2]=useState('')
    const [processing,setProcessing]=useState(false)
    const [submitted1,setSubmitted1]=useState(false)
    const [submitted2,setSubmitted2]=useState(false)

    const [verify1,setVerify1]=useState(false)
    const [verify2,setVerify2]=useState(false)

const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [user,setUser]=useState('')
const[auth,setAuth]=useCookies(['auth-kf'])


// useEffect(() => {
//   if(auth["auth-kf"])(
//     window.location.href='/dashboard'
//   )
  
 
// }, [])

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)

}
    const signINClicked=()=>{
      if(login.length>4){
        setProcessing(true)
      if(login.includes('@')){
        const formData=new FormData
  formData.append('login',login)
  formData.append('loginType','email')

  fetch(live+'auth',
  {
    method:'POST',
    body:formData
  }
  ).then((res)=>res.json())
  .then(res=>signInResp(res,'email'))
       
      }
      else{
        const formData=new FormData
        formData.append('login',login)
        formData.append('loginType','phone')
      
        fetch(live+'auth',
        {
          method:'POST',
          body:formData
        }
        ).then((res)=>res.json())
        .then(res=>signInResp(res,'phone'))

      }
     


    }
    else{
      setMsg('Enter valid Credentials !')

      setOpen(true)
    }
    }





    const signInResp=(resp,val)=>{
      if(resp.status){
        setUser(resp.data)
        setLoginType(val)
        setProcessing(false)

        setActiveOtp('signIn')
      }
      else{
        setMsg(resp.detail)
        setProcessing(false)
      
        setOpen(true)
      }
    }
    const ref=React.useRef()
const signUpClicked=()=>{

if(data.phonenumber.length===10){
  setProcessing(true)
  const formData=new FormData
  formData.append('name',data.name)
  formData.append('email',data.email)
  formData.append('phonenumber',data.phonenumber)


  fetch(live+'user',
{
  method:'POST',
  body:formData
}
  ).then((res)=>res.json())
  .then((resp)=>signupResp(resp))

}
else{
  setMsg('Enter valid Credentials !')
  setOpen(true)
}
}
const signupResp=(resp)=>{
  if(resp.status){
    setUser(resp.data)

    setProcessing(false)
  setActiveOtp('signUp')
}else{
  setMsg(resp.detail)
  setProcessing(false)

  setOpen(true)
}

}


const verifyClicked=(val)=>{
  if(val==='email'){
    setVerify2(true)
  }
  else{
    setVerify1(true)
  }
  const formData=new FormData
  formData.append('email',data.email)
  formData.append('phonenumber',data.phonenumber)
  formData.append('otp',val==='email'?otp2:otp1)
  formData.append('loginType',val)

fetch(live+'verify',
{
  method:'POST',
  body:formData
}
).then((resp)=>resp.json())
.then((res)=>verifyRes(res,val))
.catch((err)=>console.log(err))


}
const verifyClicked1=()=>{
  setVerify1(true)

  const formData=new FormData
  formData.append('email',login)
  formData.append('phonenumber',login)
  formData.append('otp',otp1)
  formData.append('loginType',loginType)

fetch(live+'verify',
{
  method:'POST',
  body:formData
}
).then((resp)=>resp.json())
.then((res)=>verifyRes1(res))
.catch((err)=>console.log(err))


}

const verifyRes1=(val)=>{
  if(val.status){
    setAuth('auth-kf',user,{
      maxAge: 3600 ,
      expires:0,
      path:'/'
   })
      setSubmitted2(true)
      props.handleClose()


      // window.location.href='/'
    


  }
  else{
    setMsg(val.detail)
  
    setVerify1(false)

    setOpen(true)
  }
}

const verifyRes=(val,val1)=>{
  if(val.status){
    if(val1&&val1==='email'){

      setSubmitted2(true)
    }
    else{

      setSubmitted1(true)

    }

  }
  else{
    setMsg(val.detail)
    setVerify1(false)
    setVerify2(false)
    setOpen(true)
  }
}


const proceedClicked=()=>{
  setProcessing(true)

  const formData=new FormData
  formData.append('email',data.email)
  fetch(live+'user',
  {
    method:'PUT',
    body:formData
  }
  ).then((res)=>res.json())
  .then(resp=>proceedRes(resp))
}
const proceedRes=(res)=>{
  if(res.status){
    setAuth('auth-kf',res.data,{
      maxAge: 3600 ,
      expires:0,
      path:'/'
   })
   props.handleClose()

    // window.location.href='/'
  }
  else{
    setMsg(res.detail)
    setProcessing(false)

    setOpen(true)
  }
}

const Active=(val)=>{

  setData('')
  setOTP1('')
  setOTP2('')
  setProcessing(false)
  setSubmitted1(false)
  setSubmitted2(false)
  setActiveOtp('')
  setActive(val)


}

const resendOtp1=()=>{
  if(login.includes('@')){
    const formData=new FormData
    formData.append('val',login)
    formData.append('otpType','email')
    fetch(live+'verify',
    {
      method:'PUT',
      body:formData
    }
    ).then((res)=>res.json())
    .then((resp)=>resendResp(resp))
    
  }
  else{
    {
      const formData=new FormData
      formData.append('val',login)
      formData.append('otpType','phone')
      fetch(live+'verify',
      {
        method:'PUT',
        body:formData
      }
      ).then((res)=>res.json())
      .then((resp)=>resendResp(resp))
      
    }
  }

}


const resendOtp=(val)=>{
  if(val==='email'){
    const formData=new FormData
    formData.append('val',data.email)
    formData.append('otpType',val)
    fetch(live+'verify',
    {
      method:'PUT',
      body:formData
    }
    ).then((res)=>res.json())
    .then((resp)=>resendResp(resp))
    
  }
  else{
    {
      const formData=new FormData
      formData.append('val',data.phonenumber)
      formData.append('otpType',val)
      fetch(live+'verify',
      {
        method:'PUT',
        body:formData
      }
      ).then((res)=>res.json())
      .then((resp)=>resendResp(resp))
      
    }
  }

}

const resendResp=(resp)=>{
  if(resp.status){
    setOpen1(true)
    setMsg(resp.detail)

  }
  else{
    setOpen(true)
    setMsg(resp.detail)
  }

}

// if(auth['auth-kf'])(
//   window.location.href='/dashboard'

// )
// else{
  return(
    <div style={{minHeight:'50vh',background:'white'}} className="signIN_bg">
<Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>
       { active==='signIn'&&!activeOtp?
       <Container  style={{paddingBottom:30}}>
            <Grid item sm={12} xs={12}>
                <Typography className="signIN_head1"><span style={{color:'var(--redColor)'}}>CUSTOMER</span> SIGN IN</Typography>
            </Grid>

           <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
        <Paper elevation={6} className="signIN_ppr">
            <Grid sm={12} xs={12}>
            <Typography className="phone_head1">
                Enter your Email / Phonenumber
            </Typography>
            </Grid>
            <Grid sm={12}  xs={12}>
          <TextField  onChange={(e)=>setLogin(e.target.value)} style={{width:'90%'}} label={'Email/Phonenumber'} placeholder="Enter email or phone number" />
            </Grid>
<Grid item sm={12} xs={12}>
<Button onClick={()=>signINClicked()} className="btn_signin">
    {!processing?'SIGN IN':'Processing'}
</Button>
</Grid>
<Grid item sm={12} xs={12}>
<Typography onClick={()=>Active('signUp')}  className="new_customer1">
New to <span style={{color:'var(--redColor)'}}>Knowledge</span>focal?

<a className="link_register">Create an account</a>
</Typography>
</Grid>
        </Paper>
        </Grid>
        </Container>:
         active==='signIn'&&activeOtp==='signIn'?
         <Container  style={{paddingBottom:30}}>
              <Grid item sm={12} xs={12}>
                  <Typography className="signIN_head1"><span style={{color:'var(--redColor)'}}>CUSTOMER</span> SIGN IN</Typography>
              </Grid>

             <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
          <Paper elevation={6} className="signIN_ppr">
              <Grid sm={12} xs={12}>
              <Typography className="phone_head1">
                  Enter Your OTP
              </Typography>
              </Grid>
              <Grid sm={12}  xs={12}>
            <TextField  value={otp1} onChange={(e)=>setOTP1(e.target.value)} style={{width:'90%'}} label={'OTP'} placeholder={`Enter otp sent on ${loginType}`}/>
              </Grid>
<Grid item sm={12} xs={12}>
  <Button onClick={()=>verifyClicked1()} className="btn_signin">
  {verify1?'Verifying':'Verify'}
  </Button>
</Grid>
<Grid item sm={12} xs={12}>
  <Typography onClick={()=>resendOtp1()}  className="new_customer1">
  Resend <span style={{cursor:'pointer',color:'var(--redColor)'}}>OTP</span>

  </Typography>
</Grid>
          </Paper>
          </Grid>
          </Container>

      : 
      
      
      active==='signUp'&&activeOtp==='signUp'?
      <Container  style={{paddingBottom:30}}>
           <Grid item sm={12} xs={12}>
               <Typography className="signIN_head1"><span style={{color:'var(--redColor)'}}>CUSTOMER</span> SIGN UP</Typography>
           </Grid>

          <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
       <Paper elevation={6} className="signIN_ppr">
{   !submitted1? <>         <Grid sm={12} xs={12}>
           <Typography className="phone_head1">
               Enter Your OTP(Phone)
           </Typography>
           </Grid>
           <Grid sm={12}  xs={12}>
         <TextField  value={otp1} onChange={(e)=>setOTP1(e.target.value)} style={{width:'90%'}} label={'OTP'} placeholder={`Enter otp sent on phonenumber`}/>
           </Grid>
<Grid item sm={12} xs={12}>
<Button onClick={()=>verifyClicked('phone')} className="btn_signin">
{verify1?'Verifying':'Verify'}
</Button>
</Grid>
<Grid item sm={12} xs={12}>
<Typography  onClick={()=>resendOtp('phone')}  className="new_customer1">
Resend <span style={{cursor:'pointer',color:'var(--redColor)'}}>Phone OTP</span>

</Typography>
</Grid>
</>:
<Grid item sm={12} xs={12}>
<Typography className="phone_head1">
Phonenumber Verified<span><i style={{color:'green',paddingLeft:5}} class="fas fa-check-circle"></i></span>
</Typography>
</Grid> }



{   !submitted2? <>    
<Grid sm={12} xs={12}>
           <Typography className="phone_head1">
               Enter Your OTP(Email)
           </Typography>
           </Grid>
           <Grid sm={12}  xs={12}>
         <TextField value={otp2} onChange={(e)=>setOTP2(e.target.value)} style={{width:'90%'}} label={'OTP'} placeholder={`Enter otp sent on email`}/>
           </Grid>
<Grid item sm={12} xs={12}>
<Button onClick={()=>verifyClicked('email')} className="btn_signin">
{verify2?'Verifying':'Verify'}
   </Button>
</Grid>
<Grid item sm={12} xs={12}>
<Typography onClick={()=>resendOtp('email')}  className="new_customer1">
Resend <span style={{cursor:'pointer',color:'var(--redColor)'}}>Email OTP</span>

</Typography>
</Grid>
</>:
<Grid item sm={12} xs={12}>
<Typography className="phone_head">
Email Verified<span><i style={{color:'green',paddingLeft:5}} class="fas fa-check-circle"></i></span>
</Typography>
</Grid> }
{submitted1&& submitted2?<Grid item sm={12} xs={12} style={{paddingBottom:20}}>
<Button onClick={()=>proceedClicked()} className="btn_signin">
Proceed
</Button>
</Grid>:null}
       </Paper>
       </Grid>
       </Container>

   : 
      <Container style={{textAlign:'-webkit-center'}}>
            <Grid item sm={12} xs={12}>
            <Typography className="signIN_head1"><span style={{color:'var(--redColor)'}}>CUSTOMER</span> SIGN UP</Typography>

            </Grid>
            <Paper className="ppr21">

            <Container className="A_Form1_Container">
  <Paper elevation={0} className="A_Form1_Cont_Paper">
      <div className="A_Form1_Div">

      <div className="Floating_Form">
        <ValidatorForm onSubmit={signUpClicked}
            ref={ref}
        >
          
       
        <div className="TF_div">
            <label className="TF_Label">Name*</label>
            <TextValidator
                label=""
                onChange={(e)=>setData({...data,
                  name:e.target.value
              })}
              name="name"
              value={data.name}
                validators={['required', 'matchRegexp:[A-Za-z]+']}
                errorMessages={['Name required', 'Invalid name']}  
            />  
        </div>
        <div className="TF_div">
          <label className="TF_Label">Email*</label>
          <TextValidator
                  label=""
                  onChange={(e)=>setData({...data,
                    email:e.target.value
                })}  name="email"
                  value={data.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Email required', 'Invalid email']}
              />
        </div>
        <div className="PNDiv_SA">
          <label className="PN_Label2">Phone Number*</label>
          <TextValidator
                  label=""
                  onChange={(e)=>setData({...data,
                    phonenumber:e.target.value
                })}  name="phonenumber"
                type="number"
                  value={data.phonenumber}
                  validators={['required']}
                  errorMessages={['Phonenumber required','Invalid Input']}
              />
     
        </div>
      
<Grid item style={{textAlign:'center'}} sm={12} xs={12}>                <Button
                color="primary"
                disableElevation
                variant="contained"
                className="primary_btn"
                type="submit"
            >
                {
               !processing? 'SIGN UP':'processing'
                }
            </Button>  
            </Grid>
 
    </ValidatorForm>
    <Grid item sm={12} xs={12}>
<Typography  className="new_customer1" onClick={()=>setActive('signIn')}>
<span style={{color:'var(--redColor)'}}>Already a </span>member?

<a className="link_register">Sign In</a>
</Typography>
</Grid>
    </div>
  </div>
  </Paper>
  </Container>        </Paper>
        </Container> }

    </div>
)
// }

  
 }