import { Grid, Typography } from "@mui/material";
import React from "react";
import Accordion_Article from "./FAQ_Accordian/D6_FE_Accordian";
import './articles.css'
export default function Articles(props){
  const {articles}=props
    return(
        <>      
        <div className="bg_articles">
        <Grid container >
    <Grid item sm={12} xs={12}>
        <Typography className="bgS_textArticle">
        It takes<span style={{color:'var(--redColor)'}}> BRAINS </span>to produce a better Article
      </Typography>
    </Grid>
</Grid>
        </div>
        
          <Accordion_Article articles={articles} />
        </>

    )
}