import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useState } from "react";
import './payment.css'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { live } from "../../App";

import Axios from 'axios'
import { useCookies } from "react-cookie";
export default function Payment(props){
  const {price,plan}=props
    const[auth,setAuth]=useCookies(['auth-kf'])

    const ref=React.useRef()
const [stat,setStat]=useState('delhi')
const [processing,setProcessing]=useState(false)
    const [data,setData]=useState({})




    const handleSubmit = async (event) => {
        setProcessing(true)
        event.preventDefault();
    
        let bodyData = new FormData();
    
        bodyData.append("amount",price);
          bodyData.append('tax',18)
          bodyData.append('email',data.email)
        bodyData.append('name',data.name)
        bodyData.append('userID',auth["auth-kf"].id)

        bodyData.append('plan',plan)
        bodyData.append('addressLine1',data.addressLine1)
        bodyData.append('gstNumber',data.gst)

        bodyData.append('city',data.city)
        bodyData.append('state',stat==='delhi'?'delhi':data.stat)
        bodyData.append('country',data.country)
        bodyData.append('pincode',data.pincode?data.pincode:'')
        bodyData.append('organisation',data.organisation?data.organisation:'')

    
        await Axios({
          url: `${live}paytm`,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: bodyData,
        }).then((res) => {
  
          if (res) {
            console.log(res)
            handleSuccess(res.data.param_dict);
          }
        });
      };
      const handleSuccess = (res) => {
        let keyArr = Object.keys(res);
    let valArr = Object.values(res);

    // when we start the payment verification we will hide our Product form
    document.getElementById("paymentFrm").style.display = "none";

    // Lets create a form by DOM manipulation
    // display messages as soon as payment starts
    let heading1 = document.createElement("h2");
    heading1.innerText = "Redirecting you to the paytm....";
    let heading2 = document.createElement("h2");
    heading2.innerText = "Please do not refresh your page....";

    //create a form that will send necessary details to the paytm
    let frm = document.createElement("form");
    // frm.action = "https://securegw-stage.paytm.in/order/process/";
    
    frm.action = "https://securegw.paytm.in/order/process/";
    
    frm.method = "post";
    frm.name = "paytmForm";

    // we have to pass all the credentials that we've got from param_dict
    keyArr.map((k, i) => {
      // create an input element
      let inp = document.createElement("input");
      inp.key = i;
      inp.type = "hidden";
      // input tag's name should be a key of param_dict
      inp.name = k;
      // input tag's value should be a value associated with the key that we are passing in inp.name
      inp.value = valArr[i];
      // append those all input tags in the form tag
      frm.appendChild(inp);
    });

    // append all the above tags into the body tag
    document.body.appendChild(heading1);
    document.body.appendChild(heading2);
    document.body.appendChild(frm);
    // finally submit that form
    frm.submit();
    setProcessing(false)
          };





    return(
        
        <Container >
            <Grid container>
                <Grid container sm={7} xs={12}>
<Paper className="ppr_payment">
    <div className="payment_contM">
<Grid item sm={12} xs={12}>
    <Typography className="plan_head">
        Plan Selected - <span style={{color:'var(--redColor)'}}> {plan}</span> 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Button onClick={()=>props.backClicked()} className="back_btn">
        Back
    </Button>
</Grid>
</div>
</Paper>
                </Grid>
                <Grid container sm={5} xs={12}>
              <Paper className="ppr_payment">
    
              <Grid item sm={12} xs={12}>
    <Typography className="plan_head">
        Enter your details here..
    </Typography>
</Grid>

<Grid item sm={12} xs={12}>
<Container style={{textAlign:'-webkit-center'}} className="A_Form1_Container">
      <Paper elevation={0} className="A_Form1_Cont_Paper">
          <div className="A_Form1_Div">
  
          <div className="Floating_Form">
            <ValidatorForm 
            id="paymentFrm"
                ref={ref}
                onSubmit={handleSubmit}
            >
              
            <div className="TF_div">
                <label className="TF_Label">Name*</label>
                <TextValidator
                    label=""
                    onChange={(e)=>setData({...data,
                      name:e.target.value
                  })}
                  name="name"
                  value={data.name}
                    validators={['required', 'matchRegexp:[A-Za-z]+']}
                    errorMessages={['Name required', 'Invalid name']}  
                />  
            </div>

            <div className="TF_div">
              <label className="TF_Label">Organisation / Firm Name (if any)</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        organisation:e.target.value
                    })}  name="organisation"
                      value={data.organisation}
                  />
            </div>

            <div className="TF_div">
              <label className="TF_Label">Email*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email required', 'Invalid email']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Address Line1*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        addressLine1:e.target.value
                    })}  name="address"
                      value={data.addressLine1}
                      validators={['required']}
                      errorMessages={['Address required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">City*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        city:e.target.value
                    })}  name="city"
                      value={data.city}
                      validators={['required']}
                      errorMessages={['City required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">State*</label>
              
              <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e)=>setStat(e.target.value)} value={stat}
      >
        <FormControlLabel value="delhi" control={<Radio />} label="Delhi" />
        <FormControlLabel value="other" control={<Radio />} label="Other State" />
    
      </RadioGroup>
    </FormControl>
              
             {stat==='other'? <TextValidator
                      label=""
                      placeholder="Enter state here"
                      onChange={(e)=>setData({...data,
                        stat:e.target.value
                    })}  name="stat"
                      value={data.stat}
                      validators={['required']}
                      errorMessages={['State is required']}
                  />:null}
            </div>
            <div className="TF_div">
              <label className="TF_Label">Country*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        country:e.target.value
                    })}  name="country"
                      value={data.country}
                      validators={['required']}
                      errorMessages={['Country required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Pincode*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        pincode:e.target.value
                    })}  name="pincode"
                      value={data.pincode}
                      validators={['required']}
                      errorMessages={['Pincode required']}
                  />
            </div>
         
         
            {/* <div className="TF_div">
              <label className="TF_Label">GSTIN</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        gst:e.target.value
                    })}  name="gst"
                      value={data.gst}
                      />
            </div> */}
            
          

   
          
          
            
           
           
              

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    // disabled={submitted}
                >
                 {!processing?`Pay ₹${price}`:'Processing'}
                </Button>       
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
</Grid>

</Paper>      
                </Grid>
            </Grid>
        </Container>
    )
}