import { Chip, Container, Grid, Typography } from "@mui/material";
import React,{useRef,useEffect,useState} from "react";
import  '../../OPC/below_top/below_top.css'

import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import SignIND from "../../../../authentication/signin/signInD";
import Payment from "../../../../payment/payment";
import { serviceData } from "../../../mainServices/serviceData";

export default function Below_Top_Legal(props){
    const {pricing} =props
    const serivcesParticular=pricing.filter(val=>val.planName=='Legal Drafts')
   
    const [open, setOpen] = React.useState(false);
    const[auth,setAuth]=useCookies(['auth-kf'])
  const [payment,setPayment]=useState(false)
  const [price,setPrice]=useState('')
  const [plan,setPlan]=useState('')
    const handleClose = () => {
      // console.log('success')
      setOpen(false);
  };
  const myRef = useRef(null)

  useEffect(()=>{
      if(myRef&&myRef.current)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
      
    },[payment])
  
  const payClicked=(val,val1)=>{
      if(auth["auth-kf"]){
          setPrice(val)
          setPlan(val1)
          setPayment(true)
      }
      else{
  
          setOpen(true)
      }
  }
  
  const backClicked=()=>{
  
    
      setPayment(false)
  
  }
    return(
        <>

     <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
        <Grid container sm={12} xs={12} className="bt_OPC">
        <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Introduction
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

In India, you will find many statutes, courts and judicial and quasi-judicial authorities that cover different legal practice areas. The laws and regulations are created to enforce and protect our rights. Legal practice before different forums and areas of law is very different; even not every lawyer is expected to possess expertise in all areas. Members of the public might also not be acquainted with certain legal challenges that they might face in their day-to-day lives, which are indeed sometimes becomes a part of their lives. There might be certain basic legal services that might not even require the services of lawyers and counsels.  <br /><br />


{/* A Public Limited Company registration in India is the best suitable business structure for entrepreneurs who are planning for large-scale business operations. To register a Public Limited Company in India there should be a minimum of seven members and there is no limit on the maximum number of members/shareholders for starting a Public Limited Company.  */}

 {/* <br /><br /> */}
 We at Knowledge Focal Educare Private Limited have developed several drafts to service members of the legal profession as well as regular persons in our endeavour to provide reliable and affordable legal documents to everyone. These precedents cover major areas of law in India. We will constantly update our precedents at regular intervals to provide latest content to you. <br /> <br />
 Our motto is to serve you with the best in mind.  <br /> <br />
                        </Typography>
</Grid>
<Grid item  style={{justifyContent:'start',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography className="strike_amount" >
                         {serivcesParticular[0].price}
                        </Typography>
                        <Typography className="discount" >
                        {serivcesParticular[0].discount}% OFF              
                     </Typography>
                    </div>
                  <Chip label={`Pay ₹${serivcesParticular[0].actualPrice}/-`}  onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} className='chip_PayC'/>
                </Grid>





                    </Grid>
                    <Grid container style={{paddingTop:'10vh'}} sm={5} xs={12}>
                    <img className="imgsub_OPC" src={(require('../../../../../images/legal-side.jpg'))} />
                    </Grid>
                </Grid>
            </Container>


            <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={12} xs={12}>














<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

  <span onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} style={{color:'var(--redColor)'}}>  Click here to download </span> Legal Drafts 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_quote'>

    <span style={{color:'var(--redColor)'}}>“</span>Because there is no alternate to a Good Corporate Governance<span style={{color:'var(--redColor)'}}>”</span> 
    </Typography>
</Grid>
              
                    </Grid>
              
                </Grid>
            </Container>

            {payment&&price&&plan?<Container ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></Container>:  null}

        </Grid>


        </>
    )
}
