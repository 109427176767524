import React from 'react'
import Main_services from './mainServices/mainServices'
import Top_sectionS from './top_section/top_section'
export default function Services(props){
    const{pricing}=props
    return(
<>
<Top_sectionS />
<Main_services  pricing={pricing}/>
</>
    )
}