import { Grid, Typography } from "@mui/material";
import React from "react";
import './top_section.css';
 export default function Top_sectionS(){
    return(
        <>
        <div className="topS_bg">
<Grid container >
    <Grid item sm={12} xs={12}>
        <Typography className="bgS_text1">
        We at <b>Knowledge Focal</b> believe in harnessing reliability and effectiveness in everything we do. We put forth that same spirit when it comes to our clients with a plethora of services carefully curated for your diverse business needs.
        </Typography>
    </Grid>
</Grid>
        </div>
        </>
    )
 }