import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CircularProgress,Grid} from '@mui/material';

import { useEffect } from 'react';
import { live } from '../../App';
import { useState } from 'react';







function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



 

export default function DataGridUserInfo() {

const [users,setUsers]=useState([])
const [plans,setPlans]=useState([])
const [processing,setProcessing]=useState(false)
const renderPlan = (val) => {
  console.log(val)
  return (
      <strong>
         {val.row.PlanPurchased?<i class="fas fa-check-circle" style={{fontSize:25,color:'green'}}></i>:

         <i class="fas fa-times-circle" style={{fontSize:25,color:'red'}}></i>
         }
      </strong>
  )
}


const rowsU= users.map((item,k) => {
    return  {
      userID:item.id,

      id:k+1,
      Name: item.name,
      Email: item.email,
      Phone: item.phonenumber,
      PlanPurchased:item.planPurchased,
     
    }
}
  )
const columnsU = [
  { field: 'id', headerName: 'S No.', width: 90 },
  { field: 'userID', headerName: 'User ID', width: 70 },

  {
    field: 'Name',
    headerName: 'Name',
    width: 150,
  },
  {
    field: 'Email',
    headerName: 'Email',
    width: 210,
  },
  {
    field: 'Phone',
    headerName: 'Phone',
    // type: 'number',
    width: 110,
  },
  {
    field: 'PlanPurchased',
    headerName: 'Plan Purchased',
    sortable: false,
    renderCell: renderPlan,
    width: 160,
     },
];

const rowsP= plans.map((item,k) => {
  return  {
    id:k+1,
    Name: item.name,
    Email: item.email,
    Phone: item.phonenumber,
    User: item.user,
    PlanName: item.planName,
    PurchasedOn:item.purchasedOn


   
  }
}
)
const columnsP = [
{ field: 'id', headerName: 'S No.', width: 70 },

,
{
  field: 'User',
  headerName: 'User',
  sortable: false,
  width: 100,
   },
{
  field: 'Name',
  headerName: 'Name',
  width: 150,
},
{
  field: 'Email',
  headerName: 'Email',
  width: 210,
},
{
  field: 'Phone',
  headerName: 'Phone',
  // type: 'number',
  width: 110,
},
   {
    field: 'PlanName',
    headerName: 'Plan Name',
    sortable: false,
    width: 220,
     },
     {
      field: 'PurchasedOn',
      headerName: 'Purchased On',
      sortable: false,
      width: 160,
       },
];

    useEffect(() => {
    fetch(live+'users-list').then((res)=>res.json()).then((resp)=>userResp(resp))
    }, [])
    useEffect(() => {
        fetch(live+'plan-purchased').then((res)=>res.json()).then((resp)=>planResp(resp))
        }, [])
    
    const [value, setValue] = React.useState(0);
const userResp=(val)=>{
    setProcessing(false)
    setUsers(val)


}
const planResp=(val)=>{
    setProcessing(false)
    setPlans(val)

}
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
<React.Fragment>


{users&&users[0]?<Box sx={{ width: '100%' , minHeight:'50vh'}} style={{paddingTop:'20vh'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Plan Purchased" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <Box sx={{height:'400px',width: '100%' }}>
      <DataGrid
        rows={rowsU}
        columns={columnsU}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>  
        </TabPanel>
      <TabPanel value={value} index={1}>
      <Box sx={{height:'400px',width: '100%' }}>
      <DataGrid
        rows={rowsP}
        columns={columnsP}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>        </TabPanel>
  
    </Box>:    <Grid sm={12} style={{textAlign:'center',paddingTop:'20vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
            </Grid>}

   

     


    </React.Fragment>
    )}