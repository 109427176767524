import { Button, Container, Grid, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import {useCookies} from 'react-cookie'
import { live } from '../../App'
import { Data } from '../articles/FAQ_Accordian/D6_FE_Accordian_Data'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './dashboard.css'
export default function Profile() {
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
    const [active,setActive]=useState('')
    const[auth,setAuth]=useCookies(['auth-kf'])
    const [verify,setVerify]=useState('')
    const[processing,setProcessing]=useState(false)

    const [open,setOpen]=useState(false)
    const [open1,setOpen1]=useState(false)


    const handleClose=()=>{
        setOpen(false)
        setOpen1(false)
      
      }
    const [data,setData]=useState({
        name:auth['auth-kf'].name,
        email:auth['auth-kf'].email,
        phone:auth['auth-kf'].phonenumber
    })

    const Active=(val)=>{
    
    if(active){
if(active==='name'&&val==='name'){
    setVerify(val)
    updateUser()
    }
    if(active==='email'&&val==='email'){
        sendOtp(val)
        }
        if(active==='phone'&&val==='phone'){
            sendOtp(val)

            }
    else{
        setActive(val)
    }
    }
    else{
        setActive(val)
    }
}
const Verify=(val)=>{
    setProcessing(true)
    const formData=new FormData
    formData.append('email',active==='email'?data.email:'')
    formData.append('phonenumber',active==='phone'?data.phone:'')
    formData.append('otp',data.otp)
    formData.append('loginType',val)
  
  fetch(live+'verify',
  {
    method:'POST',
    body:formData
  }
  ).then((resp)=>resp.json())
  .then((res)=>verifyRes(res))
  .catch((err)=>console.log(err))

}

const verifyRes=(val)=>{
if(val.status){
    updateUser()
}
}
const Cancle=()=>{
    setActive('')
    setVerify('')
}


const updateUser=()=>{
    setProcessing(true)
    const formData=new FormData
    formData.append('updateValue',active)
    formData.append('value',active==='name'?data.name:active==='email'?data.email:data.phone)
    formData.append('user',auth['auth-kf'].id)

    fetch(live+'auth',
    {
       method:"PUT",
        body:formData,

    }).then((res)=>res.json()).then((resp)=>updateRes(resp))
}
const updateRes=(val)=>{
    if(val.status){
setOpen(true)
setProcessing(false)
setActive('')
setVerify('')
setData({...data,otp:''})
setAuth('auth-kf',val.data,{
    maxAge: 3600 ,
    expires:0,
    path:'/'
 })
    }
    else{
        setOpen1(true)
        setProcessing(false)
        setActive('')
        setVerify('')
    }
}




const sendOtp=(val)=>{
    setProcessing(true)
    if(val==='email'){
      const formData=new FormData
      formData.append('val',data.email)
      formData.append('otpType',val)
      fetch(live+'verify',
      {
        method:'PUT',
        body:formData
      }
      ).then((res)=>res.json())
      .then((resp)=>sendResp(resp,val))
      
    }
    else{
      {
        const formData=new FormData
        formData.append('val',data.phone)
        formData.append('otpType',val)
        fetch(live+'verify',
        {
          method:'PUT',
          body:formData
        }
        ).then((res)=>res.json())
        .then((resp)=>sendResp(resp,val))
        
      }
    }
  
  }
  
  const sendResp=(resp,val)=>{
    if(resp.status){
        setProcessing(false)
    setVerify(val)
  
    }
    else{
      setOpen1(true)
      setActive('')
      setVerify('')
    }
  
  }





  return (
    <Container>
<Grid container>
<Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">Error in Updating USer</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">Details Updated Successfully</Alert>       
  </Snackbar>
        <Grid className='acc_doc' item sm={12} xs={12}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>

{active==='name'?<TextField  label='Name' value={data.name} onChange={(e)=>setData({
    ...data,name:e.target.value
})} />:
<div className='text_profile'>
   <b> Name</b> : {auth['auth-kf'].name}
</div>
}
<Button onClick={()=>Active('name')} variant='contained' className='btn_profile'>{active==='name'&&!processing?'Save':processing&&active==='name'?'Saving':'Edit'}</Button>
{active==='name'?<Button  onClick={()=>setActive('')} variant='contained' className='btn_profile1'>Cancel</Button>
:null}
            </div>

        
               <div style={{display:'flex',alignItems:'center',justifyContent:'start',marginTop:30}}>

{active==='email'&&verify!=='email'?<TextField  label='Name' value={data.email} onChange={(e)=>setData({
    ...data,email:e.target.value
})} />:
<div className='text_profile'>
   <b> Email</b> : {auth['auth-kf'].email}
</div>
}
{verify!=='email'?<Button onClick={()=>Active('email')} variant='contained' className='btn_profile'>{active==='email'&&!processing?'Verify':active==='email'&&processing?'Verifying':'Edit'}</Button>:null}
{active==='email'&&verify!=='email'?<Button  onClick={()=>Cancle('')} variant='contained' className='btn_profile1'>Cancel</Button>
:null}

            </div>
            {active==='email'&&verify==='email'?  <div style={{display:'flex',alignItems:'center',justifyContent:'start',marginTop:30}}>

<TextField   label='Email OTP' value={data.otp} onChange={(e)=>setData({
    ...data,otp:e.target.value
})} />
<Button onClick={()=>Verify('email')} variant='contained' className='btn_profile'>{active==='email'&&!processing?"Verify OTP":'Verifying'}</Button>
<Button  onClick={()=>Cancle('')} variant='contained' className='btn_profile1'>Cancel</Button>

</div>:null}      








 <div style={{display:'flex',alignItems:'center',justifyContent:'start',marginTop:30}}>

{active==='phone'&&verify!=='phone'?<TextField  label='Phonenumber' value={data.phone} onChange={(e)=>setData({
    ...data,phone:e.target.value
})} />:

<div className='text_profile'>
   <b> Phonenumber</b> : {auth['auth-kf'].phonenumber}
</div>
}
{verify!=='phone'?<Button onClick={()=>Active('phone')} variant='contained' className='btn_profile'>{active==='phone'&&!processing?'Verify':processing&&active==='phone'?'Verifying':'Edit'}</Button>:null}
{active==='phone'&&verify!=='phone'?<Button  onClick={()=>Cancle('')} variant='contained' className='btn_profile1'>Cancel</Button>
:null}

            </div>
            {active==='phone'&&verify==='phone'?  <div style={{display:'flex',alignItems:'center',justifyContent:'start',marginTop:30}}>

<TextField   label='Phone OTP' value={data.otp} onChange={(e)=>setData({
    ...data,otp:e.target.value
})} />
<Button onClick={()=>Verify('phone')} variant='contained' className='btn_profile'>{processing&&active==='phone'?'Verifying':'Verify OTP'}</Button>
<Button  onClick={()=>Cancle('')} variant='contained' className='btn_profile1'>Cancel</Button>

</div>:null} 
        </Grid>
       
       
       
        </Grid>
    </Container>
  )
}
