import { Container, Grid, Typography,Paper } from "@mui/material";
import React from "react";
import './WCU.css'

export default function WCU(){
    return(
        <Container >
        <Grid container>
            <Grid item sm={12} xs={12}>
                <Typography className="wcu_head">
Why Choose <span style={{color:'var(--blackColor)'}}> KnowledgeFocal?</span>
                </Typography>
            </Grid>
            <Grid container style={{marginTop:20}} sm={12} xs={12}>
             <Grid className='wcu_grid' item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            An eagle’s eye on Compliance details
            </Typography>
            </Paper>        
             </Grid>
             <Grid className='wcu_grid' item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            Reduces the risk of Regulatory non compliances on corporates. 
            </Typography>
            </Paper>        
             </Grid>
             <Grid className='wcu_grid' item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            Identifies the regulatory impact of corporate actions. 
            </Typography>
            </Paper>        
             </Grid>
             <Grid className='wcu_grid' item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            Enhance the knowledge and skill set of professionals by creating values in their existing capabilities. 
            </Typography>
            </Paper>        
             </Grid>
             <Grid className='wcu_grid' item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            Encourages the adherences to statutory and regulatory compliances.   
            </Typography>
            </Paper>        
             </Grid>
             <Grid className='wcu_grid' item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            Compliance complexity is explained in a simplified manner. 
            </Typography>
            </Paper>        
             </Grid>
             {/* <Grid item sm={4} xs={12}>
        <Paper className="wcu_ppr" elevation={6}>
            <Typography className="wcu_text" >
            Drafts accompanied with the compliance modules are in word file and hence can be amended by users as per their requirements. 
            </Typography>
            </Paper>        
             </Grid> */}

            </Grid>
        </Grid>
    </Container>
    )
}