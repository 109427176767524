import { Chip, Container, Grid, Typography } from "@mui/material";
import React,{useRef,useEffect,useState} from "react";
import  '../../OPC/below_top/below_top.css'
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import SignIND from "../../../../authentication/signin/signInD";
import Payment from "../../../../payment/payment";
import { serviceData } from "../../../mainServices/serviceData";

export default function Below_Top_NBFC(props){

    const [open, setOpen] = React.useState(false);
  const[auth,setAuth]=useCookies(['auth-kf'])
const [payment,setPayment]=useState(false)
const [price,setPrice]=useState('')
const [plan,setPlan]=useState('')
  const handleClose = () => {
    // console.log('success')
    setOpen(false);
};
const {pricing} =props
    const serivcesParticular=pricing.filter((val)=>(val.planName.includes('NBFC-ND-')&&val.category==='Compliance Management-Individual Products'))
    
const myRef = useRef(null)

  useEffect(()=>{
      if(myRef&&myRef.current)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
      
    },[payment])

const payClicked=(val,val1)=>{
    if(auth["auth-kf"]){
        setPrice(val)
        setPlan(val1)
        setPayment(true)
    }
    else{

        setOpen(true)
    }
}

const backClicked=()=>{

  
    setPayment(false)

}
    return(
        <>
            <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
        <Grid container className="bt_OPC">
        {/* {payment&&price&&plan?<Payment backClicked={backClicked} price={price} plan={plan}/>:  */}
    <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Introduction
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

A Non-Banking Financial Company (NBFC) is a company registered under the Companies Act, 2013 engaged in the business of loans and advances, acquisition of shares/stocks/bonds/debentures/securities issued by Government or local authority or other marketable securities of a like nature, leasing, hire-purchase, insurance business, chit business but does not include any institution whose principal business is that of agriculture activity, industrial activity, purchase or sale of any goods (other than securities) or providing any services and sale/purchase/construction of immovable property.   <br /><br />


The working and operations of NBFCs are regulated by the Reserve Bank of India (RBI) within the framework of the Reserve Bank of India Act, 1934. 
 <br /><br />
 NBFCs that cannot accept deposits from the public, such companies are called Non-Deposit NBFCs.   <br /> <br />
                        </Typography>
</Grid>
{/* <Grid  item sm={12} xs={12}>
<Chip label='Buy ₹3000/-' className="chip_about" />
</Grid> */}
{serivcesParticular.map((val,idx)=>
    <Grid item  key={idx} style={{justifyContent:'start',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography className="strike_amount" >
                         {val.price}
                        </Typography>
                        <Typography className="discount" >
                        {val.discount}% OFF              
                     </Typography>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
                  <Typography className="discount" >
                       {val.planName}             
                     </Typography>
                     <br /> <br/>
                  </div>

                </Grid>) 
 }





                    </Grid>
                    <Grid container style={{paddingTop:'10vh'}} sm={5} xs={12}>
                    <img className="imgsub_OPC" src={(require('../../../../../images/nbfc-side.jpg'))} />
                    </Grid>
                </Grid>
            </Container>


            <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={12} xs={12}>

                    <Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Broad categorisation of NBFCs 
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

NBFCs are broadly categorised according to (i) their ability to accept deposits and (ii) Nature of Activities. Further, Scale based approach (which is applicable from Oct, 2022) also categorise NBFCs into Base layer, Mid Layer, Upper Layer and Top Layer. <b>(For complete article on NBFC categorisation please refer our Article Section)</b>.   <br /><br />


Currently Knowledge Focal Educare Private Limited is concentrating on the compliance modules, drafts, relevant laws and trackers for the two types of NBFCs <br /><br />
<b>1.Loan Company (LC)</b> (currently comes under NBFC-Investment and Credit Company (NBFC-ICC) vide RBI notification no. RBI/2018-19/130 DNBR (PD) CC. No. 097/03.10.001/2018-19 dated Feb 22, 2019 <i>“Harmonisation of different categories of NBFCs”</i>)    <br /> <br />
 
<b>2.Microfinance Institution (MFI)  </b><br /> <br /> We are continuously working on developing the required set of compliance modules, drafts, relevant laws and trackers for other type/categories of NBFCs.     <br /> <br />

                        </Typography>
</Grid>














              
                    </Grid>
              
                </Grid>
            </Container>

            <Container>
                <Grid container>
                <Grid container style={{padding:10}} sm={6} xs={12}>
                    <Grid container  sm={12} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head2">
    NBFC Loan Company  
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_text">
    Loan Company means any company which is a financial institution carrying on as its principal business the providing of finance whether by making loans or advances or otherwise for any activity other than its own but does not include an Asset Finance Company.<br /><br /><br /><br />
    There are approx. 9000+ Non-Banking Financial Company-Investment and Credit Companies (NBFC-ICC) registered with RBI as on January 31, 2022 <br/> <br/>

    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

    <span onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} style={{color:'var(--redColor)'}}>  Click here to download </span> Compliance Module, Relevant Laws, Drafts and Trackers for {serivcesParticular[0].planName}
    </Typography>
</Grid>
                    </Grid>
                </Grid>
                <Grid container  style={{padding:10}}sm={6} xs={12}>
                    <Grid container  sm={12} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head2">
    NBFC-Microfinance Institution (NBFC-MFI)     </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_text">
    Means a non-deposit taking NBFC that fulfils the following conditions: (a) Minimum Net Owned Funds of ₹5 crore. (For NBFC-MFIs registered in the North Eastern Region of the country, the minimum NOF requirement shall stand at ₹2 crore); (b) Not less than 75 per cent of its total assets are in the nature of “microfinance loans” as defined under Reserve Bank of India (Regulatory Framework for Microfinance Loans) Directions, 2022.<br /><br />
    There are approx. 100 Non-Banking Financial Company-Microfinance Institutions (NBFC-MFI) registered with RBI as on January 31, 2022  <br/> <br/>

    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

    <span onClick={()=>payClicked(serivcesParticular[1].actualPrice,serivcesParticular[1].planName)} style={{color:'var(--redColor)'}}>  Click here to download </span> Compliance Module, Relevant Laws, Drafts and Trackers for  {serivcesParticular[1].planName} 
    </Typography>
</Grid>
                    </Grid>
                </Grid>
                </Grid>
                
            </Container>
            <Grid item sm={12} xs={12}>
    <Typography style={{textAlign:'center'}} className='text_quote'>

    <span style={{color:'var(--redColor)'}}>“</span>Because there is no alternate to a Good Corporate Governance<span style={{color:'var(--redColor)'}}>”</span> 
    </Typography>
</Grid>
{payment&&price&&plan?<Container ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></Container>:  null}

        </Grid>

        </>
    )
}
