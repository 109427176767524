import { Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import './HIWorks.css'
import Image1 from '../../../images/signup.svg'
import Image2 from '../../../images/services.svg'
import Image3 from '../../../images/pay.svg'
import Image4 from '../../../images/download.svg'

export default function HIWorks(){
    return(
        <Container>
            <Grid style={{marginBottom:20}} container>
                <Grid item sm={12} xs={12}>
                    <Typography className='head_HIW'>
                    <span style={{color:'var(--redColor)'}}>   How </span>It  Works?
                        </Typography>
                </Grid>
                <Grid container sm={12} xs={12}>
                    <Grid container sm={3} xs={12}>
                        <Paper elevation={6} className='ppr_HIW'>
<Grid item sm={12} xs={12}>
    <img  src={Image1} className='icon_HIW' />
</Grid>
<Grid item sm={12} xs={12}>
  <Typography className='subHead_HIW'>
    SIGNUP/SIGNIN
  </Typography>
</Grid>
</Paper>
                    </Grid>
                    <Grid container sm={3} xs={12}>
                        <Paper elevation={6} className='ppr_HIW'>
<Grid item sm={12} xs={12}>
    <img  src={Image2} className='icon_HIW' />
</Grid>
<Grid item sm={12} xs={12}>
  <Typography className='subHead_HIW'>
    SELECT A PRODUCT 
  </Typography>
</Grid>
</Paper>
                    </Grid>

 
                    <Grid container sm={3} xs={12}>
                        <Paper elevation={6} className='ppr_HIW'>
<Grid item sm={12} xs={12}>
    <img  src={Image3} className='icon_HIW' />
</Grid>
<Grid item sm={12} xs={12}>
  <Typography className='subHead_HIW'>
   MAKE PAYMENT
  </Typography>
</Grid>
</Paper>
                    </Grid>


                    <Grid container sm={3} xs={12}>
                        <Paper elevation={6} className='ppr_HIW'>
<Grid item sm={12} xs={12}>
    <img  src={Image4} className='icon_HIW' />
</Grid>
<Grid item sm={12} xs={12}>
  <Typography className='subHead_HIW'>
   DOWNLOAD DOCUMENTS 
  </Typography>
</Grid>
</Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}



// import * as React from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import { Paper } from "@mui/material";

// const steps = [
//   "Select campaign settings",
//   "Create an ad group",
//   "Create an ad"
// ];

// export default function HorizontalLinearStepper() {
//   const [activeStep, setActiveStep] = React.useState(3);

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Stepper activeStep={activeStep}>
//         {steps.map((label, index) => {
//           const stepProps: { completed?: true } = {};
//           const labelProps: {
//             optional?: React.ReactNode;
//           } = {};

//           return (
//             <Step key={label} {...stepProps}>
             
//               <StepLabel> <Paper {...labelProps} style={{ height: "200px" }}>123</Paper>
              
              
//               </StepLabel>
//             </Step>
//           );
//         })}
//       </Stepper>
//     </Box>
//   );
// }
