import React from "react";
import { live } from "../../App";
import HIWorks from "./HIWorks/HIWorks";
import MissionV from "./M&V/m&v";
import {useCookies} from 'react-cookie'
import { useEffect,useState } from "react";
import jsPDF from 'jspdf'

import Top_section from "./top_section/top_section";
import { CircularProgress, Dialog } from "@mui/material";
import KFOCAL from'../../images/kfocalNew.png'
import { Home } from "@mui/icons-material";
export default function Homepage(props){
  const {pricing}=props
    // const [processing,setProcessing]=useState(false)
    const [success,setSuccess]=React.useState(null)

    const[auth,setAuth]=useCookies(['auth-kf'])
    const [open, setOpen] = React.useState(false);

 


   useEffect(() => {
        let search = window.location.search;
      let params = new URLSearchParams(search);
      let foo = params.get('q');
      let foo1=params.get('q1');
      if(foo){

        setOpen(true)

        let bodyData = new FormData();
      
          bodyData.append("orderID",foo);
          fetch(live+"handlepayment",{
      
                  
      
                      method:'PUT',
                 
                      body:bodyData
                      })
                      .then(res=>res.json())
                      .then((resp)=>responseApi(resp))
                      .catch(error=>console.log(error))
      }
      if(foo1){
        setOpen(true)
        setSuccess('0')      
      }
      
      },[])



      const responseApi=(resp)=>{

        if(resp.data){
          setSuccess('1')
          pdfGenerate(resp.data)
          
        
        }
        if(!resp.data){
      setOpen(false)
        }
       
      }





      const pdfGenerate=(payment)=>{

        var prevAuth=auth["auth-kf"]
        prevAuth.planPurchased=true
        setAuth('auth-kf',prevAuth,{
            maxAge:3600 ,
            expires:0
         })
    
        ////Prev Invoice  
    //      var doc=new jsPDF('portrait','px','a4','false') 
    //             doc.setFont('Helvetica','bold') 
    //      doc.setFontSize(18)
    //          doc.setTextColor('#D00000') 
    //      doc.text(40,80,'Knowledge Focal Educare Pvt. Ltd.') 
    //         
    //          doc.setTextColor('#7f7f7f') 
    //          doc.setFontSize(12)
    //         //   doc.text(40,100,'(by Dec6 Consulting Private Limited)') 
    //          doc.setFontSize(14)
    //          doc.text(40,100,'Tax Invoice') 
    //          doc.setTextColor('black') 
    //          doc.setFont('Helvetica','lighter') 
     
    //          doc.setFontSize(14) 
    //     doc.addImage(KFOCAL,'PNG',370,60,50,50) 
    //     doc.setTextColor('#D00000') 
    //    doc.text(40,180,'Bill to / Ship to :') 
    //     doc.setFontSize(12) 
    //     doc.setTextColor('black') 
    //     doc.text(40,192,payment.name) 
    // doc.text(40,204,payment.addressLine1) 
    //     doc.text(40,216,payment.city+', '+payment.stat+(payment.pincode?(', '+payment.pincode):'')) 
    //     doc.text(40,228,payment.country) 
    // // doc.text(40,240,(payment.gstNumber?`GSTIN:${payment.gstNumber}`:"")) 
    
    //     doc.setFontSize(12) 
    //     doc.text(250,180,'Invoice No') 
    //     doc.setTextColor('#7f7f7f') 
    //     doc.text(325,180,payment.orderID) 
    //     doc.setTextColor('black') 
    //     doc.text(250,192,'Invoice Date') 
    //     doc.setTextColor('#7f7f7f') 
    //     doc.text(325,192,payment.paymentOn.toString()) 
    //     doc.setTextColor('black') 
    // //     doc.text(250,204,'HSN Code') 
    // //     doc.setTextColor('#7f7f7f') 
    // //     doc.text(325,204,'998314') 
    // doc.setTextColor('black') 
    //     doc.text(250,204,'Place of supply') 
    //     doc.setTextColor('#7f7f7f') 
    //     doc.text(325,204,payment.stat) 
   
    //     doc.setTextColor('black') 
    //     doc.text(40,300,'Plan') 
    //     doc.setTextColor('#7f7f7f') 
    //     doc.text(250,300,payment.planName.toUpperCase()) 
    //     doc.setTextColor('black') 
    //     doc.text(40,320,'Product Cost') 
    //     doc.setTextColor('#7f7f7f') 
    //     doc.text(250,320,'INR '+payment.amount) 
    // //     doc.setTextColor('black') 
    // //     doc.text(40,380,(payment.CGST?(`SGST(${(parseInt(payment.taxRate))/2}%)`):'')) 
    // //     doc.setTextColor('#7f7f7f') 
    // //     doc.text(250,380,(payment.CGST?('INR'+' '+payment.CGST):'')) 
    // //      doc.setTextColor('black') 
    // //     doc.text(40,420,(payment.SGST?(`CGST(${(parseInt(payment.taxRate))/2}%)`):'')) 
    // //     doc.setTextColor('#7f7f7f') 
    // //     doc.text(250,420,(payment.SGST?('INR'+' '+payment.SGST):'') )
    // //      doc.setTextColor('black') 
    // //     doc.text(40,380,(payment.IGST?(`IGST(${payment.taxRate}%)`):"") )
    // //     doc.setTextColor('#7f7f7f') 
    // //     doc.text(250,380,(payment.IGST?(`IGST(${payment.taxRate}%)`):"" ))
    // //      doc.setTextColor('black') 
    // //     doc.text(40,payment.SGST?460:420,'Total*') 
    // //     doc.setTextColor('#7f7f7f') 
    // //     doc.text(250,payment.SGST?460:420,'INR'+' '+payment.amount)
    //     
    
    
    
    
    //   doc.setFontSize(11) 
    //     doc.setTextColor('#D00000') 
    // //     doc.text(40,490,'*Rounded off value') 
    // //     doc.text(40,520,'Note: Unless otherwise stated, tax on this invoice is not payable under reverse charge.')
    //     doc.text(40,500,'This is a computer generated invoice')
    //     doc.setTextColor('#5f5f5f') 
    //     doc.setFontSize(11) 
    //     doc.setFont('Helvetica','lighter') 
    //     doc.text(40,550,'Knowledge Focal Educare Pvt. Ltd.') 
    //     doc.text(40,562,'H.No. 47, Gali No. 1, A-1 Block, Sant Nagar,') 
    //     doc.text(40,574,'Burari, Delhi-110084, India') 
    //     // doc.text(40,586,'GSTIN: 06AAHCD9132C1ZX')
    //     
    //      doc.text(40,586,'Email: info@knowledgefocal.com ')
    //     doc.setTextColor('#6f6f6f') 
    //     doc.setFontSize(10)
    //      doc.text(160,598,'©2022 Knowledge Focal Educare Pvt. Ltd.') 
        
    //     doc.save('invoice.pdf')


/////prev ends





  
var doc=new jsPDF('portrait','px','a4','false') 
       doc.setFont('Helvetica','bold') 
doc.setFontSize(18)
    doc.setTextColor('#D00000') 
doc.text(25,80,'Knowledge Focal Educare Pvt. Ltd.') 
   
    doc.setTextColor('#7f7f7f') 
    doc.setFontSize(12)
    doc.setFontSize(14)
    doc.text(25,100,'Tax Invoice') 
    doc.setTextColor('black') 
    doc.setFont('Helvetica','lighter') 

    doc.setFontSize(14) 
    doc.addImage(KFOCAL,'PNG',310,60,130,40) 

 doc.setTextColor('#D00000') 

   doc.text(25,168,'Bill to / Ship to :') 
    doc.setFontSize(12) 
    doc.setTextColor('black') 
   doc.text(25,180,payment.name) 
        doc.text(25,192,payment.organisation?payment.organisation:' ')  
doc.text(25,204,payment.addressLine1) 
    doc.text(25,216,payment.city+', '+payment.stat+(payment.pincode?(', '+payment.pincode):'')) 
    doc.text(25,228,payment.country) 

    doc.setFontSize(12) 
    doc.text(280,168,'Invoice No') 
    doc.setTextColor('#7f7f7f') 
    doc.text(355,168,payment.invoiceID) 
    doc.setTextColor('black') 
    doc.text(280,180,'Invoice Date') 
    doc.setTextColor('#7f7f7f') 
    doc.text(355,180,payment.paymentOn.toString()) 
    doc.setTextColor('black') 

doc.setTextColor('black') 
    doc.text(280,192,'Place of supply') 
    doc.setTextColor('#7f7f7f') 
    doc.text(355,192,payment.stat) 

    doc.setTextColor('black') 
    doc.text(25,300,'Plan') 
    doc.setTextColor('#7f7f7f') 
    doc.text(150,300,payment.planName.toUpperCase()) 
    doc.setTextColor('black') 
    doc.text(25,320,'Product Cost') 
    doc.setTextColor('#7f7f7f') 
    doc.text(150,320,'INR '+payment.amount) 




  doc.setFontSize(11) 
    doc.setTextColor('#D00000') 
  doc.text(25,500,'This is a computer generated invoice')
    doc.setTextColor('#5f5f5f') 
    doc.setFontSize(11) 
    doc.setFont('Helvetica','lighter') 
doc.text(25,550,'Knowledge Focal Educare Pvt. Ltd.') 
    doc.text(25,562,'H.No. 47, Gali No. 1, A-1 Block, Sant Nagar,') 
doc.text(25,574,'Burari, Delhi-110084, India') 
    
   doc.text(25,586,'Email: info@knowledgefocal.com ')
doc.setTextColor('#6f6f6f') 
    doc.setFontSize(10)
     doc.text(160,598,'©2022 Knowledge Focal Educare Pvt. Ltd.') 

doc.save('invoice.pdf')

      }

    return(
        <>
        <div>
                <Dialog
      disableScrollLock={ true }
      open={open}
      style={{textAlign:'center'}}
      
      aria-labelledby="draggable-dialog-title"
    >
      <div style={{height:'250px',width:'100%',alignSelf:'center',fontSize:'17px'}}> 
      {!success?<>
        <h1 style={{width:'350px',paddingTop:'20px'}} >Payment processing please wait..</h1>
        <CircularProgress  style={{marginTop:'50px'}} />
        </>:null}
        {success=='1'?<h1 style={{width:'350px',marginTop:'25px',fontWeight:'bold'}} >Payment success</h1> 
       :success=='0'?<h1 style={{width:'350px',marginTop:'25px',fontWeight:'bold'}} >Payment failed</h1> :null}

        {success? <div  ><h2  onClick={()=>window.location.href='/dashboard'}style={{width:'350px',paddingTop:'25px',cursor:'pointer'}} >Proceed to <span style={{color:'var(--redColor)'}}>Dashboard</span></h2> 

       
       <Home  onClick={()=>window.location.href='/'} style={{marginTop:'10px',fontSize:'35px',cursor:'pointer',color:'var(--redColor)'}}/></div>:null}


        </div>
 

    </Dialog>
    </div>
        <Top_section  onClick={()=>window.location.href='/'}/>
        <MissionV  pricing={pricing}/>
        <HIWorks />
        </>
    )
}