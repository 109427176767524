import { Chip, Container, Grid, Typography } from "@mui/material";
import React,{useRef,useEffect,useState} from "react";
import './below_top.css';
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import SignIND from "../../../../authentication/signin/signInD";
import Payment from "../../../../payment/payment";
import { serviceData } from "../../../mainServices/serviceData";



export default function Below_Top_OPC(props){
    const {pricing} =props
    const serivcesParticular=pricing.filter((val)=>val.planName=='One Person Company')
 
    const [open, setOpen] = React.useState(false);
  const[auth,setAuth]=useCookies(['auth-kf'])
const [payment,setPayment]=useState(false)
const [price,setPrice]=useState('')
const [plan,setPlan]=useState('')
  const handleClose = () => {
    // console.log('success')
    setOpen(false);
};
   
const myRef = useRef(null)

  useEffect(()=>{
      if(myRef&&myRef.current)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
      
    },[payment])

const payClicked=(val,val1)=>{
    if(auth["auth-kf"]){
        setPrice(val)
        setPlan(val1)
        setPayment(true)
    }
    else{

        setOpen(true)
    }
}

const backClicked=()=>{

  
    setPayment(false)

}
   
   
    return(
        <>
               <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Grid container className="bt_OPC">
        {/* {payment&&price&&plan?<Payment backClicked={backClicked} price={price} plan={plan}/>:  */}
         <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Introduction
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        
One Person Company (OPC) is defined under Section 2(62) of the Companies Act, 2013 as “One Person Company” means a company which has only one person as a member. 
<br/> <br />
The Companies Act, 2013 completely revolutionized corporate laws in India by introducing several new concepts that did not exist previously. On such game-changer was the introduction of One Person Company (OPC) concept. This led to the recognition of a completely new way of starting businesses that accorded flexibility which a company form of entity can offer, while also providing the protection of limited liability that sole proprietorship or partnerships lacked. <br /><br />

An OPC is effectively a company that has only one shareholder as its member. Furthermore, members of a company are nothing but subscribers to its memorandum of association, or its shareholders (only a natural person who is an Indian citizen whether resident in India or otherwise). Such companies are generally created when there is only one founder/promoter for the business. Entrepreneurs whose businesses lie in early stages prefer to create OPCs instead of sole proprietorship business because of the several advantages that OPCs offer.  <br /><br />

Several other countries had already recognized the ability of individuals forming a company before the enactment of the new Companies Act in 2013. These included the likes of China, Singapore, UK, Australia, and the USA.<br /><br />
                        </Typography>
</Grid>
{serivcesParticular.map((val,idx)=>
    <Grid item  key={idx} style={{justifyContent:'start',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography className="strike_amount" >
                         {val.price}
                        </Typography>
                        <Typography className="discount" >
                        {val.discount}% OFF              
                     </Typography>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
    
                  </div>

                </Grid>) 
 }





                    </Grid>
                    <Grid container style={{paddingTop:'10vh'}} sm={5} xs={12}>
                    <img className="imgsub_OPC" src={(require('../../../../../images/opc-side.jpg'))} />
                    </Grid>
                </Grid>
            </Container>
            {/* } */}


            <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={12} xs={12}>

<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head_UL">
    Characteristics
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    It has a separate legal entity.
    </li>
    <li className="li_OPC">
    The liability of shareholder and/or director is limited
        </li>
        <li className="li_OPC">
        The organized version of OPC opens the avenues for more favourable banking facilities. 
        </li>
        <li className="li_OPC">
        On the death/disability of director/shareholder company can be succeed by nominee.
        </li>
        <li className="li_OPC">
        Exemptions available from various provisions under Company law.
            </li>
            <li className="li_OPC">
            Legal status and social recognition for your business. Consequently it gives suppliers and customers a sense of confidence in business. 
            </li>
</ul>
</Grid>




<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head_UL">
    *OPC in statistics 
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    More than 36,500 OPCs are active in India as on March 31, 2021. 
    </li>
    <li className="li_OPC">
    Approx. 9,000 OPCs were registered during the financial year 2020-21. 
        </li>
        <li className="li_OPC">
        OPCs have their presence in every sector of economy be it Agriculture, Manufacturing, Construction, Electricity, Gas, Mining, Community and Social Services, Finance, Trading, Insurance and what not. 
        </li>
        
</ul>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className="stat_text">
    *Statistics from 7th Annual Report on the working and administration of the Companies Act, 2013 year ended on March 31, 2021, Ministry of Corporate Affairs, Government of India. 
    </Typography>
</Grid>


<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
A One Person company may be converted into a Private or Public Company, other than a company registered under section 8 of the Act, after increasing the minimum number of members and directors to two or seven members and two or three directors, as the case may be, and maintaining the minimum paid-up capital as per the requirements of the Act for such class of company and by making due compliance of section 18 of the Act for conversion.   </Typography>
</Grid>


<Grid item sm={12} xs={12}>
    <Typography style={{width:'100%'}} className="bt_OPC_head_UL">
    Privileges to One Person Company (including but not limited to the following) 
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    They do not have to hold annual general meetings.     </li>
    <li className="li_OPC">
    Their financial statements need not include cash flow statements.         </li>
        <li className="li_OPC">
        An Annual return can be signed by director if there is no Company Secretary.         </li>
        <li className="li_OPC">
        Provisions relating to independent directors do not apply to them.              </li>
        <li className="li_OPC">
        Their articles can provide for additional grounds for vacation of a director’s office.              </li>
        <li className="li_OPC">
        Several provisions relating to meetings and quorum do not apply to them.       
               </li>
        
</ul>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

    <span onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} style={{color:'var(--redColor)'}}> Click here to download </span> Compliance Module, relevant Laws, Drafts and Trackers for One Person Company.
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_quote'>

    <span style={{color:'var(--redColor)'}}>“</span>Because there is no alternate to a Good Corporate Governance<span style={{color:'var(--redColor)'}}>”</span> 
    </Typography>
</Grid>
              
                    </Grid>
              
                </Grid>
            </Container>
            {payment&&price&&plan?<Container ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></Container>:  null}

        </Grid>

        </>
    )
}
