import React from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import {useCookies} from 'react-cookie'

function Navbar() 

{
       
  const[auth,setAuth,deleteAuth]=useCookies(['auth-kf'])
  const signoutClicked=()=>{
    deleteAuth(['auth-kf'])
    window.location.href='/'
  }
        return(
            <>
            <div className='D6_SL_Navbar_Items1'>
<Grid justifyContent={'center'} alignContent={'center'} container className='gird_nav'>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="/" onClick={()=>window.location.href='/'}   >
                              HOME
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="aboutus"   >
                              ABOUT US
                            </NavLink>
</Grid>


<Grid item className='dropdown' >
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="compliance-management"   >
COMPLIANCE MANAGEMENT
</NavLink>
<div className="dropdown-content">
  

<div className='dropdown1'>
    <a onClick={()=>window.location.href="/compliance-management"} >COMPLIANCE MANAGEMENT - COMBO PRODUCTS</a>
    <div className="dropdown-content1">
    <a onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-Loan Company + Policies Draft</a>

    <a onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-MFI + Policies Draft</a>
   
    <a onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-Loan Company + Policies Draft</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-MFI + Policies Draft</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-MFI</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + NBFC-ND-Loan Company</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-MFI</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + NBFC-ND-Loan Company</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + Legal Drafts</a>
    <a onClick={()=>window.location.href="/compliance-management"}>Private Limited Company + Legal Drafts</a>
    {/* <a onClick={()=>window.location.href="/compliance-management"}>Public Limited Company + MFI + Policies Draft</a> */}

    </div>
    </div>


    

    <div className='dropdown2'>
    <a  >COMPLIANCE MANAGEMENT - INDIVIDUAL PRODUCTS</a>
    <div className="dropdown-content2">
    <div className='dropdown3'>
    <a onClick={()=>window.location.href="/compliance-management/nbfc"} >Non-Banking Finance Company-Non Deposit taking</a>
    <div className="dropdown-content3">
   
    <a onClick={()=>window.location.href="/compliance-management/nbfc"}>Loan Company </a>
   
    <a onClick={()=>window.location.href="/compliance-management/nbfc"}>Micro Finance Institution (NBFC-MFI) </a>
    
    </div>
    </div>
    <a onClick={()=>window.location.href="/compliance-management/public-limited-company"}>Public Limited Company </a>

    <a onClick={()=>window.location.href="/compliance-management/private-limited-company"}>Private Limited Company </a>
    <a onClick={()=>window.location.href="/compliance-management/legal-drafts"}>Legal Drafts</a>

    <a onClick={()=>window.location.href="/compliance-management/one-person-company"}>One Person Company (OPC)</a>
    {/* <a onClick={()=>window.location.href="/ourcompliance-management/taxation-compliance-management"}>Limited Liability Partnership (LLP)</a> */}
    <a onClick={()=>window.location.href="/compliance-management/draft-policies"}>Policies Draft</a>

    </div>
    </div>
   
 

  </div>
                          
</Grid>
<Grid item >
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2N" : "D6_SL_Navbar_Link1N"}  to="articles"   >
ARTICLES

                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="contactus"   >
CONTACT US
                            </NavLink>

</Grid>
<Grid item>
<div className='dropdownA'>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="signin"   >
  
{!auth['auth-kf']?'SIGN IN / SIGN UP':
<i class="fas fa-user-lock"></i>}
</NavLink>
{auth['auth-kf']?<div className="dropdown-contentA">
<a onClick={()=>window.location.href="/dashboard"}>Dashboard</a>
<a onClick={()=>signoutClicked()}>Signout</a>


</div>:null}

                           
                            </div>

</Grid>

{/* <Grid item>
<div className= "D6_SL_Navbar_Link1"  onClick={()=>window.open("https://affiliate.fmstracking.online/",'_blank')}   >
                            PRIVACY PILICY
                            </div>
</Grid> */}
{/* <Grid item>
<div className= "D6_SL_Navbar_Link1"  onClick={()=>window.open("https://affiliate.fmstracking.online/",'_blank')}   >
                            TERMS & CONDITIONS
                            </div>
</Grid> */}
    </Grid>                  
            </div>








            </>
        );
    }
    
    export default Navbar;