import React from "react";
import './public.css';
import Top_section_Public from "./top_section/top_section";
import Below_Top_Public from "./below_top/below_top";

export default function Public_Limited(props){
    const {pricing}=props
    return(
        <div className="bg_sub_public">
<Top_section_Public/>
<Below_Top_Public pricing={pricing}/>
        </div>
    )
}