import { Chip, Container, Grid, Typography } from "@mui/material";
import React,{useRef,useEffect,useState} from "react";
import  '../../OPC/below_top/below_top.css'


import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useCookies} from 'react-cookie';
import SignIND from "../../../../authentication/signin/signInD";
import Payment from "../../../../payment/payment";
import { serviceData } from "../../../mainServices/serviceData";

export default function Below_Top_Public(props){
   
  const {pricing} =props
  const serivcesParticular=pricing.filter((val)=>val.planName=='Public Limited Company')

  const [open, setOpen] = React.useState(false);
  const[auth,setAuth]=useCookies(['auth-kf'])
const [payment,setPayment]=useState(false)
const [price,setPrice]=useState('')
const [plan,setPlan]=useState('')
  const handleClose = () => {
    // console.log('success')
    setOpen(false);
};
const myRef = useRef(null)

useEffect(()=>{
  if(myRef&&myRef.current)
  myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
  
},[payment])


const payClicked=(val,val1)=>{
    if(auth["auth-kf"]){
        setPrice(val)
        setPlan(val1)
        setPayment(true)
    }
    else{

        setOpen(true)
    }
}

const backClicked=()=>{

  
    setPayment(false)

}
  return(
        <>
     <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_Signin'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
         
          < SignIND handleClose={handleClose} />
        </DialogContent>
      </Dialog>
        <Grid container className="bt_OPC">
             <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head">
    Introduction
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<Typography className="bt_OPC_text">
                        

Public Limited Company is defined under Section 2(71) of the Companies Act, 2013 as <b> “Public Company”</b>means a company which: <br /><br />
<span style={{fontStyle:'italic'}}>
(a) is not a private company and;   <br /><br />

(b) has a minimum paid-up share capital as may be prescribed:   <br /><br />
</span>
<b>Provided that </b> a company which is a subsidiary of a company, not being a private company, shall be deemed to be public company for the purposes of this Act even where such subsidiary company continues to be a private company in its articles  <br /><br />


A Public Limited Company registration in India is the best suitable business structure for entrepreneurs who are planning for large-scale business operations. To register a Public Limited Company in India there should be a minimum of seven members and there is no limit on the maximum number of members/shareholders for starting a Public Limited Company. 

 <br /><br />
 A Public Limited Company in India enjoys all the privileges of a corporate entity together with the features of Limited Liability. A Public Limited Company can get itself listed with the stock exchange to raise capital from the general public. Hence, the Public Limited Companies have to comply with multiple regulations. <br /> <br />
 Also, the rules and the regulations of a Public Limited Company are more rigid and strict in comparison to the Private Limited Company.  <br /> <br />
                        </Typography>
</Grid>
{serivcesParticular.map((val,idx)=>
    <Grid item  key={idx} style={{justifyContent:'start',display:'flex',alignItems:'center'}}sm={12} xs={12}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <Typography className="strike_amount" >
                         {val.price}
                        </Typography>
                        <Typography className="discount" >
                        {val.discount}% OFF              
                     </Typography>
                  <Chip label={`Pay ₹${val.actualPrice}/-`}  onClick={()=>payClicked(val.actualPrice,val.planName)} className='chip_PayC'/>
    
                  </div>

                </Grid>) 
 }






                    </Grid>
                    <Grid container style={{paddingTop:'10vh'}} sm={5} xs={12}>
                    <img className="imgsub_OPC" src={(require('../../../../../images/public-side.jpg'))} />
                    </Grid>
                </Grid>
            </Container>


            <Container>
                <Grid container>
                    <Grid style={{padding:'10px'}} container sm={12} xs={12}>

<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head_UL">
    Characteristics
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    A public limited Company is considered to be a separate legal entity from the shareholders.
    </li>
    <li className="li_OPC">
    A public limited company raises funds from individuals as well as from financial institutions. The funds may be also raised in equity shareholding, preference shareholding, or debentures or through other instruments. 
        </li>
        <li className="li_OPC">
        It is one of the biggest advantages of a Public Limited Company, the shares can be easily transferred by a shareholder to other legal entities- be it an individual or an organization in India or abroad.       </li>
        <li className="li_OPC">
        As the organization has a vast capital base the development openings are likewise huge, particularly in the event of an open constrained organization.        </li>
        <li className="li_OPC">
        The organization is controlled by the Board of Directors. This Board of Directors is elected by the investors.         </li>
            <li className="li_OPC">
            There is no restriction on the issue of securities to the public. The company can issue the same via an initial public offer (IPO) or a bonus issue or through private placement. 

</li>
</ul>
</Grid>




<Grid item sm={12} xs={12}>
    <Typography className="bt_OPC_head_UL">
    *Public Limited Company in statistic
    </Typography>
</Grid>
<Grid  item sm={12} xs={12}>
<ul className="ul_OPC">
    <li className="li_OPC">
    65,942 Public Limited Companies are active in India as on March 31, 2021.   </li>
    <li className="li_OPC">
    Out of this 65,942 Public Limited Companies 6,740 are Listed Companies and 59,202 are Un-Listed Companies.         </li>
        <li className="li_OPC">
        3385 Public Limited Companies were registered during the financial year 2020-21.       </li>
        <li className="li_OPC">
        Public Limited Companies have their presence in every sector of economy be it Agriculture, Manufacturing, Construction, Electricity, Gas, Transportation, Communication, Mining, Community and Social Services, Finance, Trading, Insurance and what not      </li>
        
</ul>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className="stat_text">
    *Statistics from 7th Annual Report on the working and administration of the Companies Act, 2013 year ended on March 31, 2021, Ministry of Corporate Affairs, Government of India. <br /> <br />   </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography className="stat_text">
    **List of Top 10 Indian Companies with their Global ranking  <br /> <br />    </Typography>
</Grid>



<Grid item style={{textAlign:'-webkit-center'}} sm={12} xs={12}>
<table>
  <tr>
    <th>Name of the Company </th>
    <th style={{paddingLeft:10,paddingRight:10}}>India rank </th>
    <th style={{paddingLeft:10,paddingRight:10}}>Global rank </th>
  </tr>
  <tr>
    <td>Reliance Industries Limited </td>
    <td>01 </td>
    <td>54</td>
  </tr>
  <tr>
    <td>State Bank of India </td>
    <td>02</td>
    <td>105</td>
  </tr>
  <tr>
    <td>HDFC Bank Limited  </td>
    <td>03 </td>
    <td>54</td>
  </tr>

  <tr>
    <td>ICICI Bank Limited  </td>
    <td>04 </td>
    <td>205</td>
  </tr>
  <tr>
    <td>Oil & Natural Gas Corporation Limited  </td>
    <td>05</td>
    <td>229</td>
  </tr>
  <tr>
    <td>Housing Development Finance Corporation Limited  </td>
    <td>06</td>
    <td>269</td>
  </tr>
  <tr>
    <td>Indian Oil Corporation Limited  </td>
    <td>07 </td>
    <td>358</td>
  </tr>

  <tr>
    <td>Tata Consultancy Services Limited   </td>
    <td>08 </td>
    <td>385</td>
  </tr>
  <tr>
    <td>Tata Steel Limited  </td>
    <td>09</td>
    <td>408</td>
  </tr>
  <tr>
    <td>Axis Bank Limited   </td>
    <td>10</td>
    <td>432</td>
  </tr>
</table><br /> <br /> 
   </Grid>
   <Grid item sm={12} xs={12}>
    <Typography className="stat_text">
    **Source: Forbes Global 2000 list dated May 12, 2022    </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography className='text_click'>

    <span onClick={()=>payClicked(serivcesParticular[0].actualPrice,serivcesParticular[0].planName)} style={{color:'var(--redColor)'}}>  Click here to download </span> Compliance Module, relevant Laws, Drafts and Trackers for Public Limited Company 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='text_quote'>

    <span style={{color:'var(--redColor)'}}>“</span>Because there is no alternate to a Good Corporate Governance<span style={{color:'var(--redColor)'}}>”</span> 
    </Typography>
</Grid>
              
                    </Grid>
              
                </Grid>
            </Container>
            {payment&&price&&plan?<Container ref={myRef}><Payment  backClicked={backClicked} price={price} plan={plan}/></Container>:  null}

        </Grid>

        </>
    )
}
