import React from 'react'
import { useEffect } from 'react'
import { live } from '../../App'
import {useCookies} from 'react-cookie'
import KFOCAL from'../../images/kfocalNew.png'
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography
    ,Grid ,CircularProgress} from '@mui/material'
import { useState } from 'react'
import jsPDF from 'jspdf'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PaymentsInvoice() {
    const[auth,setAuth]=useCookies(['auth-kf'])
const [payments,setPayments]=useState([])
const [processing,setProcessing]=useState('')

    useEffect(() => {
        setProcessing(true)
    fetch(live+`payments-list?q=${auth['auth-kf'].id}`).then((res)=>res.json()).then((resp)=>payRes(resp))
    }, [])
    const payRes=(val)=>{
        setProcessing(false)
setPayments(val)
    }



    const pdfGenerate=(payment)=>{

  
         
         var doc=new jsPDF('portrait','px','a4','false') 
                doc.setFont('Helvetica','bold') 
         doc.setFontSize(18)
             doc.setTextColor('#D00000') 
         doc.text(25,80,'Knowledge Focal Educare Pvt. Ltd.') 
            
             doc.setTextColor('#7f7f7f') 
             doc.setFontSize(12)
            //   doc.text(25,100,'(by Dec6 Consulting Private Limited)') 
             doc.setFontSize(14)
             doc.text(25,100,'Tax Invoice') 
             doc.setTextColor('black') 
             doc.setFont('Helvetica','lighter') 
     
             doc.setFontSize(14) 
        doc.addImage(KFOCAL,'PNG',310,60,130,40) 

     doc.setTextColor('#D00000') 
    
       doc.text(25,168,'Bill to / Ship to :') 
        doc.setFontSize(12) 
        doc.setTextColor('black') 
        doc.text(25,180,payment.name) 
        doc.text(25,192,payment.organisation?payment.organisation:' ') 
    doc.text(25,204,payment.addressLine1) 
        doc.text(25,216,payment.city+', '+payment.stat+(payment.pincode?(', '+payment.pincode):'')) 
        doc.text(25,228,payment.country) 
    // doc.text(25,240,(payment.gstNumber?`GSTIN:${payment.gstNumber}`:"")) 
    
        doc.setFontSize(12) 
        doc.text(280,168,'Invoice No') 
        doc.setTextColor('#7f7f7f') 
        doc.text(355,168,payment.invoiceID) 
        doc.setTextColor('black') 
        doc.text(280,180,'Invoice Date') 
        doc.setTextColor('#7f7f7f') 
        doc.text(355,180,payment.paymentOn.toString()) 
        doc.setTextColor('black') 
    //     doc.text(280,204,'HSN Code') 
    //     doc.setTextColor('#7f7f7f') 
    //     doc.text(355,204,'998314') 
    doc.setTextColor('black') 
        doc.text(280,192,'Place of supply') 
        doc.setTextColor('#7f7f7f') 
        doc.text(355,192,payment.stat) 
   
        doc.setTextColor('black') 
        doc.text(25,300,'Plan') 
        doc.setTextColor('#7f7f7f') 
        doc.text(150,300,payment.planName.toUpperCase()) 
        doc.setTextColor('black') 
        doc.text(25,320,'Product Cost') 
        doc.setTextColor('#7f7f7f') 
        doc.text(150,320,'INR '+payment.amount) 
  
    
    
    
      doc.setFontSize(11) 
        doc.setTextColor('#D00000') 
           doc.text(25,500,'This is a computer generated invoice')
        doc.setTextColor('#5f5f5f') 
        doc.setFontSize(11) 
        doc.setFont('Helvetica','lighter') 
        doc.text(25,550,'Knowledge Focal Educare Pvt. Ltd.') 
        doc.text(25,562,'H.No. 47, Gali No. 1, A-1 Block, Sant Nagar,') 
        doc.text(25,574,'Burari, Delhi-110084, India') 
        
         doc.text(25,586,'Email: info@knowledgefocal.com ')
        doc.setTextColor('#6f6f6f') 
        doc.setFontSize(10)
         doc.text(160,598,'©2022 Knowledge Focal Educare Pvt. Ltd.') 
        
        doc.save('invoice.pdf')
      }


    if(!processing&&payments[0]){
        return (
          <Grid className='acc_doc' sm={12} xs={12}>
       {payments.map((val,idx)=>
       
          
       
        <Accordion  key={idx}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Payment ID: {val.invoiceID}</Typography>
        </AccordionSummary>
      
        <AccordionDetails>
        
          <Typography>
           Email: {val.email}
          </Typography>
        
        </AccordionDetails>
        <AccordionDetails>
         
          <Typography>
           Amount: {val.amount} 
          </Typography>
       
        </AccordionDetails>
        <AccordionDetails>
        <Typography>
          <Grid container>
          <Grid item>
          Paid On: {val.paymentOn} 
    
          </Grid>
          <Grid style={{alignSelf:'center'}} item>
          <Button  variant="outlined" onClick={()=>pdfGenerate(val)} style={{backgroundColor:'var(--redColor)',height:'25px',fontSize:'12px',alignItems:'center',marginLeft:'30px',color:'white'}}>
              Download 
              </Button>
    
          </Grid>
           </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>)}
      </Grid>
        
          )
    }
    if(processing){
        return(
            <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
            </Grid>)
    }
    if(!processing&&!payments[0]){
      return(
        <Grid sm={12} style={{textAlign:'center'}} className='acc_doc' xs={12} item>
           <Typography className="head_doc">No Payments Found</Typography>

        </Grid>)
    }

}
