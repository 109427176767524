import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import './top_section.css'
import {useCookies} from 'react-cookie'
import { useEffect,useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
export default function Top_section(){
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const [open,setOpen]=useState(false)
      const[auth,setAuth]=useCookies(['auth-kf'])
      useEffect(() => {
        if(auth["auth-kf"]){
            if(auth["auth-kf"].planPurchased){

            }
            else{
                setOpen(true)
            }
        }
        
      
        
       
      }, [])

      const handleClose=()=>{
        setOpen(false)
      
      }
    return(
        <>
        <div className="img_home">
            <Grid conatiner>
            <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open}  onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="info">No Plan Purchased, please purchase a plan to unlock downloads</Alert>       
  </Snackbar>
                <Grid item sm={12} xs={12}>
                    <Typography className="home_headT">
                    {/* Knowledge Focal Educare Private limited  */}
 &nbsp;

                </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className="home_textT">
                 
                    Commitment for Good Governance initiative
                    </Typography>
                </Grid>
            </Grid>
        </div>
        <Container>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Typography className="home_headT2">
                    Knowledge Focal Educare Private Limited
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {/* <Typography className="home_textT2">
                    Regardless of your company size, we aim to meet and exceed your expectations and help your business experience extraordinary growth and development. Regardless of the service packages you choose, you can be assured that your business is in good hands.
                    </Typography> */}
                </Grid>
            </Grid>
        </Container>
        </>

    )
}