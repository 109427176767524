import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import  '../../PoliciesDrafts/policies.css'

export default function Top_section_Policies(){
    return(
        <Grid container>
        <Grid container className="cont_top_OPC">
           <Container> 
        <Grid container>
            <Grid item sm={12} xs={12}>
                <Typography className="top_OPC_head">
                   DRAFT POLICIES
                </Typography>
            </Grid>

        </Grid>
        <Grid container style={{justifyContent:'center'}} >
            <Grid item>
                <Typography className="top_OPC_text">
                Building the foundations
                </Typography>
            </Grid>
        </Grid>
        </Container>
        </Grid>
        </Grid>
    )
}