import React from "react";
import './nbfc.css';

import Top_section_NBFC from "./top_section/top_section";
import Below_Top_NBFC from "./below_top/below_top";

export default function NBFC(props){
    const {pricing}=props
    return(
        <div className="bg_sub_nbfc">
<Top_section_NBFC/>
<Below_Top_NBFC pricing={pricing}/>
        </div>
    )
}