export const Data = [
  {
    question: 'Appointment of Auditor & Filing of Form ADT - 01 to the Registrar of Companies. ',
    answer: 'Despite technology intervention in business ecosystem, a stark reality is that the Compliance function continues to operate with a data and technology architecture that lags the standards required to manage the emerging risk landscape'
  },  
  {
      question: 'Preparation, Printing & Distribution of Share Certificates to the Respective Share Holders',
      answer: 'Preparation, Printing & Distribution of Share Certificates to the Respective Share Holders '
    },

    {
      question: 'Filing of MPB-01 (Disclosure of Interest by Directors) to The Registrar of Companies.',
      answer: 'Filing of MPB-01 (Disclosure of Interest by Directors) to The Registrar of Companies. '
    },
    {
      question: 'Filing of DIR – 08 (Disclosure of Non-Disqualification by Directors) to The Registrar of Companies. ',
      answer: 'Filing of DIR – 08 (Disclosure of Non-Disqualification by Directors) to The Registrar of Companies.'
    },
 
    {
      question: 'Filing of Minutes of 04 Board Meeting & Minutes of AGM (Annual General Meeting) to The Registrar of Companies.',
      answer: 'Filing of Minutes of 04 Board Meeting & Minutes of AGM (Annual General Meeting) to The Registrar of Companies.'
    },
    {
      question: 'Filing of 07 Mandatory Registers to The Registrar of Companies.',
      answer: 'Filing of 07 Mandatory Registers to The Registrar of Companies.'
    },
    {
      question: 'Filing of Balance Sheet, P & L Accounts, Audit Report, Director’s Report to The Registrar of Companies',
      answer: 'Filing of Balance Sheet, P & L Accounts, Audit Report, Director’s Report to The Registrar of Companies'
    },
    {
      question: 'Filing of Extract of Annual Returns & Financial Statements to The Registrar of Companies.',
      answer: 'Filing of Extract of Annual Returns & Financial Statements to The Registrar of Companies.'
    },
    {
      question: 'Filing of Annual Returns Form AOC 04 (Financials Related Annual Return) to The Registrar of Companies.',
      answer: 'Filing of Annual Returns Form AOC 04 (Financials Related Annual Return) to The Registrar of Companies.'
    },
  ];